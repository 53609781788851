import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Select, message, Row, Checkbox, DatePicker } from 'antd';
import * as api from '@api/index';
import * as lib from '@utils/lib'
import axios from 'axios';
import { bankList } from '@utils/constants';
import TermsAndConditions from './payment/Terms';
import * as constants from '@utils/constants';


const businessEncodingKey = process.env.REACT_APP_BUSINESS_ENCODING_KEY;

const innopayMid = process.env.REACT_APP_INNOPAY_MID;
const innopayLicenseKey = process.env.REACT_APP_INNOPAY_LICENSE_KEY;

const SellerRegist = ({ visible, onClose }) => {
  const memKey = lib.getLocalStorage('prptbk-token', 'mem_key') || '';

  const [form] = Form.useForm();
  const [timer, setTimer] = useState(10); // 3분 타이머
  const [timerActive, setTimerActive] = useState(false);
  const [verificationData, setVerificationData] = useState({
    business_tp: '',
    phone: '',
    verificationCode: '',
  });

  const initialValues = {
    business_tp: 'INDIVIDUAL_BUSINESS'
  }

  const [businessDisabled, setBusinessDisabled] = useState(false); // 사업자 인증 여부

  const [accountBusinessType, setAccountBusinessType] = useState("P"); //계좌구분
  const [accountVerifed, setAccountVerifed] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTermsId, setSelectedTermsId] = useState(null);

  const [isNumberAuthentication, setIsNumberAuthentication] = useState(false);

  useEffect(() => {
    let countdown;
  
    if (timerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0 && timerActive) {
      message.error("인증 시간이 초과되었습니다."); // 메시지는 여기서 1번만 뜸
      setTimerActive(false); // 타이머 비활성화
    }
  
    return () => clearInterval(countdown); // 타이머 정리
  }, [timer, timerActive]);

  // 모달 닫을 때 모든 데이터 초기화
  const resetModal = () => {
    form.resetFields();
    setTimer(180);
    setTimerActive(false);
    setVerificationData({ business_tp: '', phone: '', verificationCode: '' });
    setBusinessDisabled(false)
    setAccountVerifed(false);
    setIsNumberAuthentication(false);
    setAccountBusinessType('P')
  };

  const startPhoneVerification = async () => {
    try{
      await form.validateFields(['business_tp', 'phone']);
      const { business_tp, phone } = form.getFieldsValue()
      const params = {
        phone : phone,
      }
      setVerificationData((prev) => ({...prev, business_tp: business_tp, phone: phone}));

      const response = await api.sendSMS(params);

      if(response.status === 200){
        setVerificationData((prev) => ({...prev, verificationCode : response.data}));
        message.success('인증번호가 발송되었습니다.');
        setTimerActive(true);
        setTimer(180); // 타이머 초기화
      }else{
        message.error(response.data);
      }
    } catch (error) {
      message.error('사업자 구분과 전화번호를 정확히 입력해주세요.');
    }
  };

  const verifyPhoneCode = () => {
    form.validateFields(['business_tp','phone','verificationCode'])
      .then(({ business_tp, phone, verificationCode }) => {
        if(verificationData.business_tp !== business_tp || verificationData.phone !== phone){
          message.error('입력하신 정보가 일치하지 않습니다.');
        }else if(verificationData.verificationCode !== Number(verificationCode)){
          message.error('인증번호가 일치하지 않습니다.');
        }else{
          message.success('본인 인증이 완료되었습니다.');
          setTimerActive(false);
          setIsNumberAuthentication(true)
        }
      })
      .catch(() => {
        message.error('인증번호를 정확히 입력해주세요.');
      });
  };

  // Step 2 사업자 인증
  const handleBusinessVerification = async () => {
    form
      .validateFields(["business_no", "company_nm", "represent_nm", "fund_dt"])
      .then((values) => {

        const params = {
          "businesses": [
            {
              "b_no": values.business_no.replaceAll('-', ''),
              "start_dt": values.fund_dt,
              "p_nm": values.represent_nm,
              "p_nm2": "",
              "b_nm": values.company_nm,
              "corp_no": "",
              "b_sector": "",
              "b_type": "",
              "b_adr": "",
            }
          ]
        }

        axios.post(
          'http://api.odcloud.kr/api/nts-businessman/v1/validate?serviceKey='+businessEncodingKey, params
        )
        .then((response) => {
          if(response.data.data[0].status){
            message.success('사업자 인증 완료');
            setBusinessDisabled(true);
          }else{
            message.error('정확한 사업자 정보를 입력해주세요.');
          }
        })
        .catch((error) => {
          console.log(error)
        })
      })
      .catch(() => {
        message.error("사업자 정보를 정확히 입력해주세요.");
      });
  };

  const verifyBankAccount = async () => {

    form
      .validateFields(["bank_cd", "holder_nm", "account", "identityNumber"])
      .then(async (values) => {

        const url = 'https://acct.innopay.co.kr/AcctNmReq.acct';

        const params = {
          mid: innopayMid, // 이노페이에서 발급한 MID
          merkey: innopayLicenseKey, // 이노페이에서 발급한 라이선스키
          moid: 'moid', // 가맹점 거래번호
          bankCode: values.bank_cd, // 은행코드
          acntNo: values.account, // 입금계좌번호
          idNo: values.identityNumber, // 실명번호 (생년월일 6자리 또는 사업자번호 10자리)
          acntNm: values.holder_nm, // 예금주명
        }

        try {
          axios.post(url, params, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            if(response.data.resultCode === '0000'){
              message.success("정상적으로 인증되었습니다.");
              setAccountVerifed(true);
            }else{
              message.error(`${response.data.resultMsg}\n에러코드 : ${response.data.resultCode}`);
            }
            console.log(response);
          });
      
        } catch (error) {
          message.error('계좌 실명 조회 오류:', error);
          console.error('계좌 실명 조회 오류:', error);
          throw error;
        }
      })
      .catch((error) => {
        console.log(error)
      })
  };

  const handleFinish = async () => {
    console.log(form.getFieldValue('terms2'))
    if(!isNumberAuthentication){
      message.error('전화번호를 인증해주세요.');
      return false;
    }else if(!businessDisabled){
      message.error('사업자 정보를 인증해주세요.');
      return false;
    }else if(!accountVerifed){
      message.error('계좌 정보를 인증해주세요.');
      return false;
    }else if(!form.getFieldValue('terms2')){
      message.error('개인정보 수집 및 이용안내를 확인해주세요.');
      return false;
    }

    form.validateFields()
    .then(async (response) => {
      if(await window.confirm('판매자 정보를 등록하시겠습니까?')){
        let sellerInfo = form.getFieldsValue();
        const bankInfo = constants.bankList.find((item) => item.value === form.getFieldValue('bank_cd'));
        sellerInfo = {...sellerInfo, bank_nm: bankInfo.label, phone: sellerInfo.phone.replaceAll('-', '')};
        
        const response = await api.makeSeller(sellerInfo);
        if(response.status === 200){
          message.success(response.data);
          onClose();
          resetModal();
        }else{
          message.error(response.data);
        }
      }
    })
    .catch((error) => {
      message.error('모든 정보를 입력해주세요')
    })
  };

  useEffect(() => {
    if (visible) {
      const originalStyle = window.getComputedStyle(document.body).overflow;

      document.body.style.overflow = 'hidden';
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;

      return () => {
        document.body.style.overflow = originalStyle;
        document.body.style.paddingRight = '0px';
      };
    }
  }, [visible]);

  return (
    <>
      <Modal
        className="sellerModal"
        title="판매자 정보등록"
        open={visible}
        onCancel={() => {
          resetModal();
          onClose();
        }}
        footer={null}
        maskClosable={false}
      >
        <Form form={form} layout="horizontal" initialValues={initialValues}>
          {/* 개인정보 수집 영역 */}
          <Form.Item
            style={{ marginBottom: '0' }}
            name='terms2' valuePropName="checked" rules={[{ required: true, message: '필수 동의 항목입니다.' }]}>
            <Row style={{gap: '8px'}}>
              <Checkbox onChange={(e) => form.setFieldValue('terms2', e.target.checked)}><span>개인정보 수집 및 이용안내 (필수)</span></Checkbox>
              <button
                type='button'
                style={{textDecoration:'underline'}}
                onClick={() => {
                setSelectedTermsId('terms2');
                setIsModalVisible(true);
              }}>상세보기</button>
            </Row>
          </Form.Item>
          <ul>

            {/* 첫번째 리스트, 본인인증영역 */}
            <li>
              <Form.Item
                name="business_tp"
                label="사업자 구분"
                rules={[{ required: true, message: "사업자 유형을 선택해주세요." }]}
              >
                <div className="btn-label">
                  <label className="">
                    <input 
                      type="radio" 
                      name="business_tp" 
                      value="INDIVIDUAL_BUSINESS" 
                      onChange={(e) => {
                        setAccountBusinessType('P')
                        form.setFieldValue('business_tp', e.target.value)
                      }}
                      checked={accountBusinessType === 'P'}
                      disabled={isNumberAuthentication}
                    />
                    개인
                  </label>
                  <label className="">
                    <input 
                      type="radio" 
                      name="business_tp" 
                      value="CORPORATE"
                      onChange={(e) => {
                        setAccountBusinessType('C')
                        form.setFieldValue('business_tp', e.target.value)
                      }}
                      checked={accountBusinessType === 'C'}
                      disabled={isNumberAuthentication}
                    />
                    법인
                  </label>
                </div>
              </Form.Item>
              <Form.Item
                className="flex1"
                name="phone"
                label="전화번호"
                rules={[
                  { required: true, message: "전화번호를 입력해주세요." },
                  {
                    pattern: /^(01[016789]-?\d{3,4}-?\d{4}|02-?\d{3,4}-?\d{4}|0[3-9]{1}[0-9]{1}-?\d{3,4}-?\d{4})$/,
                    message: '올바른 전화번호 형식이 아닙니다.',
                  },
                ]}
              >
                {!timerActive ? (
                  <Input 
                    placeholder="'-' 없이 숫자만 입력해주세요." 
                    onChange={(e) => form.setFieldValue('phone', e.target.value)}
                    value={isNumberAuthentication ? verificationData.phone : form.getFieldValue('phone')}
                    disabled={isNumberAuthentication}
                    maxLength={11}
                  />
                ) : null}
                {!timerActive ? (
                  <button type='button' className="btn-seller" onClick={startPhoneVerification} disabled={isNumberAuthentication}>본인 인증</button>
                ) : null}
      
                {timerActive && (
                  <p>
                    인증번호 입력 <br/>(남은 시간: {Math.floor(timer / 60)}분 {timer % 60}초)
                  </p>
                )}
                {timerActive && (
                  <Form.Item
                    name="verificationCode"
                    rules={[{ required: true, message: "인증번호를 입력해주세요." }]}
                  >
                    <Input placeholder="6자리 숫자"  maxLength={6}/>
                  </Form.Item>
                )}
                {timerActive ? (
                  <Button type="primary" onClick={verifyPhoneCode}>인증 확인</Button>
                ) : null}
              </Form.Item>
            </li>

            {/* 두번째 리스트, 사업자인증영역  */}
            <li className="divided">
              <div style={{paddingBottom: '16px', marginBottom: '16px', borderBottom: '1px solid #ccc'}}>
                <Form.Item
                  name="business_no"
                  label="사업자 등록번호"
                  rules={[
                    { required: true, message: "사업자 등록번호를 입력해주세요." },
                    // { pattern: /^\d{10}$/, message: "사업자 등록번호는 10자리 숫자여야 합니다." },
                    {
                      pattern: /^\d{3}-\d{2}-\d{5}$/,
                      message: "사업자 등록번호는 000-00-00000 형식으로 입력해주세요.",
                    },
                  ]}
                >
                  <Input 
                    placeholder="예: 123-45-12345" 
                    disabled={businessDisabled} 
                    maxLength={12}
                    onChange={(e) => {
                      let value = e.target.value.replace(/[^0-9-]/g, ''); // 숫자와 하이픈만 허용
                      form.setFieldValue('business_no', value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="represent_nm"
                  label="대표자명"
                  rules={[{ required: true, message: "대표자명을 입력해주세요." }]}
                >
                  <Input placeholder="대표자명" disabled={businessDisabled} maxLength={20}/>
                </Form.Item>
                <Form.Item
                  name="fund_dt"
                  label="개업일자"
                  rules={[{ required: true, message: "개업일자를 입력해주세요." }]}
                >
                  <DatePicker
                      placeholder="개업일자"
                      format="YYYY-MM-DD"
                      allowClear={false}
                      disabled={businessDisabled}
                      onChange={(date, dateString) => {
                          form.setFieldValue('fund_dt', dateString.replaceAll('-', ''))
                      }}
                  />
                  <button type='button' className="btn-seller" onClick={handleBusinessVerification} disabled={businessDisabled}>
                    사업자 인증</button>
                </Form.Item>
              </div>
              <div>
                {/* Line1 */}
                <div>
                  <Form.Item
                    name="company_nm"
                    label="상호"
                    rules={[
                      { required: true, message: "상호를 입력해주세요." },
                    ]}
                  >
                    <Input placeholder="상호명" disabled={businessDisabled} maxLength={100}/>
                  </Form.Item>
                  <Form.Item
                    name="addr"
                    label="사업장 주소"
                    rules={[
                      { required: true, message: "주소를 입력해주세요." },
                    ]}
                  >
                    <Input placeholder="주소" maxLength={100}/>
                  </Form.Item>
                </div>
                {/* Line2 */}
                <div>
                  {accountBusinessType !== 'P' && (
                    <Form.Item
                      name="corp_no"
                      label="법인 등록번호"
                      rules={[
                        { required: true, message: "법인 등록번호를 입력해주세요." },
                      ]}
                    >
                      <Input placeholder="예: 123456-1234567" maxLength={20}/>
                    </Form.Item>
                  )}
                  <Form.Item
                    name="business_div"
                    label="업태"
                    rules={[
                      { required: true, message: "업태를 입력해주세요." },
                    ]}
                  >
                    <Input placeholder="업태" maxLength={20}/>
                  </Form.Item>
                </div>
                {/* Line3 */}
                <div>
                  <Form.Item
                    name="company_tp"
                    label="종목"
                    rules={[
                      { required: true, message: "종목을 입력해주세요." },
                    ]}
                  >
                    <Input placeholder="종목" maxLength={20}/>
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="세금계산서용 이메일"
                    rules={[
                      { required: true, message: "이메일을 입력해주세요." },
                      {
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "올바른 이메일 형식이 아닙니다.",
                      },
                    ]}
                  >
                    <Input placeholder="이메일 (예: example@example.com)" maxLength={50}/>
                  </Form.Item>
                </div>
              </div>
            </li>

            {/* 세번째 리스트, 담당자영역 */}
            {accountBusinessType !== 'P' && (
              <li className="manager">
                <Form.Item
                  name="manager_nm"
                  label="담당자 이름"
                  rules={[
                    { required: true, message: "이름을 입력해주세요." },
                    {
                      max: 5,
                      message: "이름은 최대 5자까지 입력 가능합니다.",
                    },
                  ]}
                >
                  <Input placeholder="이름" maxLength={5}/>
                </Form.Item>
                <Form.Item
                  name="manager_position"
                  label="담당자 직위"
                  rules={[
                    { required: true, message: "직위를 입력해주세요." },
                    {
                      max: 5,
                      message: "직위는 최대 10자까지 입력 가능합니다.",
                    },
                  ]}
                >
                  <Input placeholder="직위"  maxLength={10}/>
                </Form.Item>
                <Form.Item
                  name="manager_phone"
                  label="담당자 연락처"
                  rules={[
                    { required: true, message: "연락처를 입력해주세요." },
                  ]}
                >
                  <Input placeholder="'-' 없이 숫자만 입력해 주세요." maxLength={20} />
                </Form.Item>
                <Form.Item
                  name="manager_email"
                  label="담당자 이메일"
                  rules={[
                    { required: true, message: "이메일을 입력해주세요." },
                    {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "올바른 이메일 형식이 아닙니다.",
                    },
                  ]}
                >
                  <Input placeholder="예: example@example.com" maxLength={50} />
                </Form.Item>
              </li>
            )}

            {/* 네번째 리스트, 계좌검증영역 */}
            <li className="divided bank">
              <div>
                <Form.Item
                  name="bank_cd"
                  label="은행                "
                  rules={[{ required: true, message: "은행을 입력해주세요." }]}
                >
                  <Select 
                    showSearch
                    placeholder="은행" 
                    options={bankList}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    disabled={accountVerifed}
                  />
                </Form.Item>
                <Form.Item
                  name="holder_nm"
                  label="예금주"
                  rules={[{ required: true, message: "예금주를 입력해주세요." }]}
                >
                  <Input placeholder="예금주" disabled={accountVerifed} maxLength={20}/>
                </Form.Item>
                <Form.Item
                  name="account"
                  label="계좌번호"
                  rules={[{ required: true, message: "계좌번호를 입력해주세요." }]}
                >
                  <Input placeholder="'-' 없이 숫자만 입력해주세요." disabled={accountVerifed} maxLength={16}/>
                </Form.Item>
              </div>
              <Form.Item
                name="identityNumber"
                label={accountBusinessType === 'P' ? '생년월일' : '사업자등록번호'}
                rules={[{ required: true, message: accountBusinessType === 'P' ? '생년월일을 입력해주세요.' : '"-" 없이 숫자만 입력해주세요.' }]}
              >
                <Input 
                  placeholder={accountBusinessType === 'P' ? '예: 901225' : "'-' 없이 숫자만 입력해주세요."}
                  disabled={accountVerifed}
                  onChange={(e) => form.setFieldValue('identityNumber', e.target.value)}
                  maxLength={accountBusinessType === 'P' ? 6 : 10}
                />
                <button className="btn-seller" type="button" onClick={verifyBankAccount} disabled={accountVerifed}>
                  계좌 인증
                </button>
              </Form.Item>
            </li>
          </ul>

        </Form>
        <div className="sellerModal-btn">
          <Button type="primary" onClick={handleFinish}>
            완료
          </Button>
          <Button onClick={() => onClose()}>
            취소
          </Button>
        </div>
      </Modal>
      <TermsAndConditions visible={isModalVisible} onClose={() => setIsModalVisible(false)} termsId={selectedTermsId}/>
    </>
  );
};

export default SellerRegist;
