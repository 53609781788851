import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, message, Modal, Drawer, Rate, Select, Button, Tooltip, Collapse, Dropdown, Image } from 'antd';
import JSZip from 'jszip';
// icon
import heartIcon from '@assets/images/common/i-heart-line.svg';
import activeHeartIcon from '@assets/images/common/active_heart_icon.png';
import saveIcon from '@assets/images/common/i-mark-line.svg';
import activeSaveIcon from '@assets/images/common/active_save_icon.png';
import activeSirenIcon from '@assets/images/common/i-sirenOn.svg';
import sirenIcon from '@assets/images/common/i-siren.svg';
import downliadIcon from '@assets/images/common/white_download_icon.png';
import marketIcon from '@assets/images/common/market_icon.png';
import mobileList from '@assets/images/common/i-list.svg';
import questionIcon from '@assets/images/common/question_icon.png';
import whiteQuestionIcon from '@assets/images/common/white_question_icon.png';
import playingIcon from '@assets/images/common/playing_icon.svg';
import playIcon3 from '@assets/images/common/play_icon3.png';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import randomIcon from '@assets/images/common/random_icon.svg';
import partyIcon from '@assets/images/profile/party_icon.png';
import newIcon from '@assets/images/profile/new_icon2.png';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import before10Seconds from '@assets/images/common/before_10_seconds.svg';
import after10Seconds from '@assets/images/common/after_10_seconds.svg';
import previewReason from '@assets/images/common/preview_reason.svg';
import downArrow from '@assets/images/common/down_arrow_icon.svg';
import musicIcon from '@assets/images/common/music_icon.png';
import imageIcon from '@assets/images/common/image_icon.png';
import videoIcon from '@assets/images/common/video_icon.png';
import textIcon from '@assets/images/common/text_icon.png';
// component
import ReceiptModal from '@components/payment/ReceiptModal';
import CopyButton from '@pages/myPages/ButtonCopy';
import CartButton from '@pages/myPages/ButtonCart';
import DirectBuyButton from '@pages/myPages/ButtonDirectBuy';
import RequestBad from '@pages/myPages/RequestBad';
import ListReview from '@pages/myPages/ListReview';
import ListReviewMobile from '@pages/myPages/ListReviewMobile';
import RejectReason from '@pages/myPages/RejectReason';
import ListCardPrompt from '@pages/marketplaces/ListCardPrompt';
import SliderCardPrompt from '@pages/marketplaces/SliderCardPrompt';
// hook
import useWidth from '@hooks/useWidth';
// constants
import { AUTH_BASIC, AUTH_LOGIN, badge_map, innopayResultCode, POPUP_MUSIC_PLAYER, POPUP_VIDEO_PLAYER } from '@utils/constants';
// lib
import {
  isAdmin,
  isPaidUser,
  isEmpty,
  getLocalStorage,
  formatEncodingDate,
  formatNumber,
  validateSession,
  makePaymentUUID,
  makeSelectBoxList,
  koKRFormat,
  handleImageError,
  formatEncodingBoardDate,
} from '@utils/lib';
// api
import * as api from '@api/index';
// pathname
import * as PATH from '@routes/pathName';
import { IMAGES } from '@utils/image';
// env
const innopayMid = process.env.REACT_APP_INNOPAY_MID;
const innopayLicenseKey = process.env.REACT_APP_INNOPAY_LICENSE_KEY;

const PromptDetail = (props) => {
  const width = useWidth();
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const memTel = getLocalStorage('prptbk-token', 'mem_tel') || ''; // 이노페이 필요정보
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memNick = getLocalStorage('prptbk-token', 'mem_nick') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';
  const memAuth = getLocalStorage('prptbk-token', 'mem_auth') || '';
  const memPlanCd = getLocalStorage('prptbk-token', 'plan_cd') || '';
  const isMobile = width < 700;
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const prpt_id = state?.prpt_id;

  const [writerProfile, setWriterProfile] = useState({}); // 작성자 프로필
  const [promptData, setPromptData] = useState({}); // 프롬프트 상세
  const [fileList, setFileList] = useState([]); //첨부파일
  const [downList, setDownList] = useState([]); //첨부파일
  const [reviews, setReviews] = useState([]);
  const [isPurchases, setIsPurchases] = useState(false); // 구매여부
  const [isReview, setIsReview] = useState(false); // 리뷰작성 여부
  const [isLike, setIsLike] = useState(false); // 좋아요 여부
  const [isSaveMusic, setIsSaveMusic] = useState(false); // 음악 보관함 여부
  const [isWriter, setIsWriter] = useState(true); // 작성자 여부 (로그인사용자==작성자)
  const [cateRankList, setCateRankList] = useState([]); // 작성자 랭킹 리스트

  const playerRef = useRef(); // 순수 audio 태그를 컨트롤하기 위해 useRef 사용
  const [musicPlayFlag, setMusicPlayFlag] = useState(false); // 음악 플레시 시 플레이 count 업데이트를 위한 플래그

  const descTextRef = useRef(); // 프롬프트 설명(가사)
  const guideTextRef = useRef(); // 프롬프트 활용 가이드
  const prptTextRef = useRef(); // 프롬프트
  const [showMore, setShowMore] = useState({ desc: false, guide: false, prpt: false }); // more 버튼 show 여부
  const [descMoreFlag, setDescMoreFlag] = useState(false); // 설명 더보기 버튼
  const [guideMoreFlag, setGuideMoreFlag] = useState(false); // 설명 더보기 버튼
  const [prptMoreFlag, setPrptMoreFlag] = useState(false); // 프롬프트 더보기 버튼

  const [badRsnList, setBadRsnList] = useState([]); // 신고하기 공통코드
  const [requestBadModal, setRequestBadModal] = useState(false); // 신고하기모달

  const [reviewForm] = Form.useForm(); // 리뷰 form
  const [reviewFlag, setReviewFlag] = useState({}); // 리뷰 플래그
  const [replyContents, setReplyContents] = useState({}); // 리뷰 답글 input
  const [reviewModal, setReviewModal] = useState(false); // 전체리뷰 웹
  const [reviewModal2, setReviewModal2] = useState(false); // 전체리뷰 모바일

  const [badgeList, setBadgeList] = useState([]); // 뱃지 리스트
  const [aiModelList, setAiModelList] = useState([]); // Ai 모델 리스트

  const payInfo = useRef({});
  const gdsInfo = useRef({});

  const [popularList, setPopularList] = useState([]);
  const [recentList, setRecentList] = useState([]);

  const [rejectRsnList, setRejectRsnList] = useState([]); // 판매거절 공통코드
  const [rejectReasonModal, setRejectReasonModal] = useState(false); // 판매거절 요청하기 모달

  const [viewImage, setViewImage] = useState(); // 선택한 뷰 이미지 파일경로
  const [visible, setVisible] = useState(false);

  // 뷰 이미지 변경
  const changeViewImage = (filePath) => {
    setViewImage(filePath);
  };

  // 판매거절 요청하기 모달창 열기
  const openRejectReasonModal = () => {
    setRejectReasonModal(true);
  };

  // 판매거절 요청하기 모달창 닫기
  const closeRejectReasonModal = () => {
    setRejectReasonModal(false);
  };

  // 신고하기 모달창 열기
  const openRequestBadModal = () => {
    // 로그인이 되어있고, 신고하기를 하지 않은 경우만 모달 open
    if (!validateSession({ authType: AUTH_LOGIN })) {
      message.error('로그인 후 이용가능합니다');
      navigate(PATH.LOGIN);
    } else if (promptData?.prpt_bad === 'Y') {
      message.error('신고 접수된 프롬프트 입니다.');
    } else {
      setRequestBadModal(true);
    }
  };

  // 신고하기 모달창 닫기
  const closeRequestBadModal = () => {
    setRequestBadModal(false);
  };

  // 바로구매 모달창 닫기
  const closeReceiptModal = () => {
    setIsReceiptModalVisible(false);
  };

  // 바로구매 모달창 열기
  const openReceiptModal = async () => {
    // 로그인 체크
    if (!validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
      return;
    }
    gdsInfo.current = {
      mem_key: memKey,
      gds_div: 'GDS003', //프롬프트
      gds_key: prpt_id,
      gds_nm: promptData?.prpt_title,
      gds_amt: promptData?.sale_amt,
      gds_qty: 1,
    };

    payInfo.current = {
      mem_key: memKey,
      gds_nm: promptData?.prpt_title,
      inout_tp: 'OUT', // 구매
      inout_div: '01', // 01 통합결제/ 무료인경우?
      pay_amt: promptData?.sale_amt,
      pay_meth: '',
      gdsList: [gdsInfo.current],
      pay_no: makePaymentUUID(memKey),
    };

    if (promptData?.sale_amt === 0) {
      if (await window.confirm('구매하시겠습니까?')) {
        savePayInfo();
      }
    } else {
      setIsReceiptModalVisible(true);
    }
  };

  // 신고사유 공통코드 조회
  const getCommonCode = async () => {
    try {
      const { data } = await api.fetchEqualCommonGroupCode({ grp_cd: 'BAD_RSN' });
      const madeSelectBoxList = makeSelectBoxList(data, 'cd_nm', 'cd');
      setBadRsnList(madeSelectBoxList);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };

  // 신고사유 공통코드 조회
  const getCommonCodeRejectRsn = async () => {
    try {
      const { data } = await api.fetchEqualCommonGroupCode({ grp_cd: 'REJECT' });
      const madeSelectBoxList = makeSelectBoxList(data, 'cd_nm', 'cd');
      setRejectRsnList(madeSelectBoxList);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };

  // 작성자 프로필 조회
  const getProfile = async (key) => {
    const params = { mem_key: key };

    const { data } = await api.userProfile({ params });
    setWriterProfile(data);
    setIsWriter(memKey == data.mem_key); // 로그인 사용자 == 작성자 여부
    const newCateRankList = data?.agg_cate?.split(',')?.map((cateCd, index) => {
      return {
        agg_cate: cateCd,
        agg_rank: data?.agg_rank?.split(',')[index],
      };
    });
    const filteredList = newCateRankList.filter((item) => item.agg_cate !== 'CATE002');
    const sortedCateRankList = filteredList?.sort((a, b) => a?.agg_rank - b?.agg_rank);
    setCateRankList(sortedCateRankList || []);
  };

  // 상세조회
  const getPromptDetail = async (query) => {
    try {
      if (!query.prpt_id) {
        message.warning('로그인 후 이용가능합니다');
        navigate(PATH.LOGIN);
        return false;
      }

      const { data } = await api.getPromptDetail(query);
      // 상세
      setPromptData(data.detailData);

      // 구매여부
      setIsPurchases(data.checkedPurchases > 0);

      // 첨부파일
      if (data.fileList) {
        setFileList(data.fileList.filter((file) => file.attr1 == 'a'));
      }

      // 유저 프로필
      getProfile(data.detailData.mem_key);

      // 뷰 이미지
      changeViewImage(data.detailData.thum_path);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  // 리뷰조회
  const searchReview = async (query) => {
    try {
      const { data } = await api.getSearchReview(query);
      setReviews(data.reviewData);
      setIsReview(data.checkedReview > 0);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  // 리뷰 저장
  const registerReview = async (params) => {
    try {
      const { data } = await api.registerReview(params);
      if (data.returnStatus == 'success') {
        message.info(data.returnMessage);
        getPromptDetail({ prpt_id: prpt_id, mem_key: memKey }); // 상세조회
        searchReview({ prpt_id: prpt_id, mem_key: memKey });
      }
    } catch (error) {
      message.error('저장에 실패했습니다.');
    }
  };

  // 좋아요 조회
  const getPromptLike = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.getMyPromptList({ mem_key: memKey, keyword_tab: 'likeList', prpt_id: prpt_id });
      setIsLike(data.total > 0);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 추가&삭제
  const toggleLikes = useCallback(
    async (type) => {
      try {
        if (!memKey) return;
        const { data } = await api.increaseLikeCount({
          target_id: prpt_id,
          like_mem_key: memKey,
          own_mem_key: promptData?.mem_key,
          like_div: 'P',
          fir_id: memKey,
        });
        if (!data) return;
        getPromptLike(); // 좋아요 조회
        message.success(type === 'add' ? '좋아요 등록되었습니다.' : '좋아요 삭제되었습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [promptData],
  );

  // 음악 보관함 목록 가져오기
  const listSavedMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.listMyMusic({ mem_key: memKey, prpt_id: prpt_id });
      if (!data) return;
      setIsSaveMusic(data.total > 0);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 추가
  const addMyMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.addMyMusic([
        {
          mem_key: memKey,
          prpt_id: prpt_id,
          prpt_title: promptData?.prpt_title,
        },
      ]);
      if (!data) return;
      await listSavedMusic();
      message.success('마이뮤직에 담겼습니다.');
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 삭제
  const deleteMyMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.deleteMyMusic([
        {
          mem_key: memKey,
          prpt_id: prpt_id,
        },
      ]);
      if (!data) return;
      await listSavedMusic();
      message.success('마이뮤직에서 삭제되었습니다.');
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 신고하기
  const requestBadPrompt = async (reportData) => {
    try {
      const params = {
        target_id: prpt_id,
        bad_mem_key: memKey, // 신고자
        own_mem_key: promptData?.mem_key, // 신고대상
        bad_cd: reportData?.bad_cd,
        bad_cd_label: reportData?.bad_cd_label,
        contents: reportData?.contents,
        prpt_title: promptData?.prpt_title,
      };
      const response = await api.addPromptBad(params);
      message.info(response.data.returnMessage);

      if (response.data.returnStatus == 'success') {
        getPromptDetail({ prpt_id: prpt_id, mem_key: memKey }); // 상세조회
        closeRequestBadModal();
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 판매거절 요청하기
  const requestReject = async (rejectReason) => {
    try {
      const params = {
        prpt_id,
        prpt_stat: '98',
        reject_rsn_cd: rejectReason?.reject_rsn_cd,
        reject_rsn: rejectReason?.reject_rsn,
      };
      const response = await api.updatePromptReview([params]);
      message.info(response.data.returnMessage);

      if (response.data.returnStatus === 'success') {
        getPromptDetail({ prpt_id: prpt_id, mem_key: memKey }); // 상세조회
        closeRejectReasonModal();
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 판매중/판매중지 요청하기
  const requestOnOff = async (prptStat, ownMemKey) => {
    window.confirm(prptStat === '30' ? '판매하기로 변경하시겠습니까?' : '판매중지 하시겠습니까?').then(async (result) => {
      if (result) {
        try {
          const params = {
            prpt_id,
            prpt_stat: prptStat,
            old_stat: promptData?.prpt_stat,
            mem_key: ownMemKey,
          };
          const response = await api.updatePromptReview([params]);
          message.info(response.data.returnMessage);

          if (response.data.returnStatus === 'success') {
            getPromptDetail({ prpt_id: prpt_id, mem_key: memKey }); // 상세조회
          } else {
            return;
          }
        } catch (error) {
          console.error(error);
        }
      } else return;
    });
  };

  // 삭제하기
  const requestDelete = async (prptStat) => {
    window.confirm('삭제 하시겠습니까?', 'delete').then(async (result) => {
      if (result) {
        try {
          const params = {
            prpt_id,
            prpt_stat: prptStat,
          };
          const response = await api.deletePromptReview([params]);
          message.info(response.data.returnMessage);

          if (response.data.returnStatus === 'success') {
            navigate(PATH.MARKET_SALES_HOME, { replace: true });
          } else {
            return;
          }
        } catch (error) {
          console.error(error);
        }
      } else return;
    });
  };

  // 마켓플레이스 뱃지 리스트 조회
  const listBadge = async () => {
    try {
      const { data } = await api.getBadgeInfo();
      setBadgeList(data);
    } catch (error) {
      console.error('마켓플레이스 뱃지 리스트 조회 error', error);
    }
  };

  // 프롬프트 소유자의 마켓플레이스 뱃지 가져오기
  const getMemberBadge = useCallback(
    (bdgCd) => {
      if (!bdgCd || !badgeList.length) return [];
      return badgeList.filter((badge) => bdgCd.includes(badge.bdg_cd));
    },
    [badgeList],
  );

  // AI 모델 리스트 조회
  const listAiModel = async () => {
    try {
      const { data } = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      setAiModelList(data);
    } catch (error) {
      console.error('AI 모델 리스트 조회 error', error);
    }
  };

  // 프롬프트 소유자의 전문 AI 분야 가져오기
  const getMemberAiModel = useCallback(
    (aiFlCd) => {
      if (!aiFlCd || !aiModelList.length) return [{ cd_nm: '전문 AI 분야를 설정하세요' }];
      return aiModelList.filter((aiModel) => aiFlCd.includes(aiModel.cd));
    },
    [aiModelList],
  );

  // 마켓플레이스로 이동
  const goMarketPlace = (cateCd) => {
    switch (cateCd) {
      case 'CATE001':
        navigate(PATH.IMAGE_HOME);
        break;
      case 'CATE002':
        navigate(PATH.VIDEO_HOME);
        break;
      case 'CATE003':
        navigate(PATH.MUSIC_HOME);
        break;
      case 'CATE004':
        navigate(PATH.TEXT_HOME);
        break;
    }
  };

  // 유저 프로필 이동
  const goProfile = () => {
    if (promptData?.mem_stat != '02') {
      alert('작성자가 이미 회원이 아닙니다.\n프로필을 조회할 수 없습니다.');
      return false;
    }
    navigate(PATH.PROFILE_PAGE, {
      state: {
        mem_email: promptData?.mem_email,
        mem_key: promptData?.mem_key,
        cate_cd: promptData?.cate_cd,
      },
    });
  };

  // 프롬프트 복사
  const copyText = () => {
    const textToCopy = promptData?.test_prpt || '';
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert('복사되었습니다!');
      })
      .catch((err) => {
        console.error('복사 실패:', err);
      });
  };

  // 음악 플레이
  const handleMusicPlay = async () => {
    try {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        if (!musicPlayFlag) {
          console.log('musicplay');
          // 해당 페이지에서 처음 플레이 했을 경우 play count 업데이트
          await api.updatePromptView({ prpt_id: prpt_id, view_type: 'P' });

          getPromptDetail({ prpt_id: prpt_id });
        }
        setMusicPlayFlag(true);
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 다운로드
  const downLoadFile = async () => {
    try {
      // 뮤직 카테고리의 경우에만 유료회원 이상만 다운로드 가능 (본인, 관리자 제외)
      if (promptData?.cate_cd === 'CATE003') {
        if (!isWriter && !isAdmin(memAuth) && !validateSession({ authType: AUTH_BASIC, isCallbackConfirm: true })) {
          return;
        }
      }

      fileList.map(async (file, index) => {
        downList.push(file);
      });

      /* 이미지인 경우 썸네일을 다운로드 파일로 포함  */
      if (promptData?.cate_cd === 'CATE001') {
        const thum_path = promptData?.thum_path || '';
        const thum_ext = thum_path ? thum_path.split('.')[1] : '';

        const thum = { file_dwn_path: thum_path, file_seq: 0, file_org_nm: promptData?.prpt_title + '_thum.' + thum_ext };

        if (thum_path) {
          downList.unshift(thum);
        }
      }

      // 파일이 없을 경우
      if (downList.length === 0) return message.warning('파일이 존재하지 않습니다.');

      if (downList.length > 1) {
        // 파일이 1개 초과일 경우 zip 으로 묶어서 다운로드
        const zip = new JSZip();
        const filePromises = downList.map(async (file, index) => {
          const response = await fetch(file.file_dwn_path);
          const blob = await response.blob();
          zip.file(file.file_org_nm, blob); // 파일명
        });

        await Promise.all(filePromises);

        // zip -> blob 로 변환 후 다운로드
        zip.generateAsync({ type: 'blob' }).then((content) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(content);
          link.download = `${promptData?.prpt_title}.zip`; // zip 파일 이름
          link.click();
        });
      } else {
        // 1개일 경우 그냥 다운로드
        const response = await fetch(downList[0].file_dwn_path);
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = downList[0].file_org_nm; // 다운로드될 파일 이름
        link.click();
      }
      // 해당 프롬프트 다운로드수 카운트업
      await api.updatePromptView({ prpt_id: prpt_id, view_type: 'D' });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setDownList([]);
    }
  };

  // 리뷰 전체보기 토글
  const reviewToggle = useCallback(() => {
    if (width < 700) {
      setReviewModal2(!reviewModal2); //모바일
    } else {
      setReviewModal(!reviewModal); // 웹
    }
  }, [reviewModal, width, reviewModal2]);

  // 답글 버튼 클릭 시
  const handleReply = (seq) => {
    setReviewFlag((prev) => ({ ...prev, [seq]: prev[seq] === undefined ? true : !prev[seq] }));
  };

  // 답글 수정 시
  const handleReplyChange = (seq, value) => {
    setReplyContents((prev) => ({ ...prev, [seq]: value })); // 특정 reviewId에 해당하는 답글 내용만 업데이트
  };

  // 리뷰 저장 시
  const handleSaveReview = (saveData) => {
    if (isEmpty(saveData.contents)) {
      message.error('리뷰를 남겨 주세요.');
      return;
    }
    const params = {
      prpt_id: prpt_id,
      score_mem_key: memKey, // 로그인 사용자
      own_mem_key: promptData?.mem_key, // 프롬프트 작성자
      score: saveData.score || 0,
      contents: saveData.contents,
      parent_seq: '',
      prpt_title: promptData?.prpt_title,
    };

    registerReview(params); // 리뷰 저장
  };

  // 답글 저장 시
  const handleSaveReply = (review) => {
    const replyContent = replyContents[review.seq];
    if (isEmpty(replyContent)) {
      message.error('답글을 남겨 주세요.');
      return;
    }

    const params = {
      prpt_id: prpt_id,
      score_mem_key: review.score_mem_key,
      own_mem_key: review.own_mem_key,
      score: '',
      contents: replyContent,
      parent_seq: review.seq,
    };

    registerReview(params); // 답글 저장
  };

  // 장바구니 담기
  const addToCart = async () => {
    // 로그인 체크
    if (!validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
      return;
    }

    gdsInfo.current = {
      mem_key: memKey,
      gds_div: 'GDS003', //프롬프트
      gds_key: prpt_id,
      gds_nm: promptData?.prpt_title,
      gds_amt: promptData?.sale_amt,
      gds_qty: 1,
    };

    try {
      const response = await api.addToCart([gdsInfo.current]);
      message.info(response.data.returnMessage);
    } catch (error) {
      console.error(error);
    } finally {
      getPromptDetail({ prpt_id: prpt_id, mem_key: memKey });
      searchReview({ prpt_id: prpt_id, mem_key: memKey });
    }
  };

  // 바로구매
  const handleOpenPayment = async (paymentType) => {
    // 로그인 체크
    if (!validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
      return;
    }

    payInfo.current = {
      ...payInfo.current,
      pay_meth: paymentType,
    };

    if (memTel) {
      window.innopay_result = (data) => {
        if (data.ResultCode === '3001') {
          savePayInfo();
        } else {
          const resultMessage = innopayResultCode.find((item) => item.key === data.ResultCode);
          message.error('에러가 발생했습니다.\n에러사유 : ' + resultMessage);
          return;
        }
      };

      if (window.innopay && window.innopay.goPay) {
        window.innopay.goPay({
          PayMethod: 'CARD', // 결제수단
          MID: innopayMid, // 가맹점 MID
          MerchantKey: innopayLicenseKey,
          Amt: payInfo.current.pay_amt + '', // 결제금액
          GoodsName: payInfo.current.gds_nm, // 상품명
          BuyerName: memNick, // 고객닉네임
          BuyerTel: memTel, // 고객 전화번호
          BuyerEmail: memEmail, // 고객 이메일
          ResultYN: 'N', // 결제 결과창 출력 여부
          Moid: '' + payInfo.current.pay_no, // 주문번호
        });
      } else {
        api.deleteMemberPayment(payInfo.current);
        console.error('이노페이 SDK가 로드되지 않았습니다.');
      }
    } else {
      if (await window.confirm(`내 프로필에서 전화번호 등록 후 결제가 가능합니다.\n전화번호를 등록하시겠습니까?`)) {
        navigate(PATH.PROFILE_PAGE, {
          state: {
            mem_email: memEmail,
            mem_key: memKey,
            profileDetail: 'true',
          },
        });
      }
    }
  };

  // 구매 저장
  const savePayInfo = async () => {
    try {
      const response = await api.insertPaymentMstForPaySeq(payInfo.current);
      if (response.status === 200) {
        try {
          await api.insertSaleInfo(response.data); // 판매자 거래정보 프로시저
          api.deleteCartItemByKey([gdsInfo.current]); // 장바구니 삭제
          api.sendPaymentCompleteAlert({ mem_key: memKey, pay_no: payInfo.current.pay_no, pay_type: 'CART' }); // 결제 완료 알림
          setIsReceiptModalVisible(false);
          message.success('구매가 완료되었습니다.');
          navigate(PATH.MY_MARKET_HOME);
        } catch (e) {
          message.error('결제정보 업데이트 도중 에러가 발생했습니다.');
        }
      } else {
        message.error('결제정보 업데이트 도중 에러가 발생했습니다.');
      }
    } catch (e) {
      console.error(e);
    } finally {
      getPromptDetail({ prpt_id: prpt_id, mem_key: memKey });
    }
  };

  const handleSendMessage = async (options = {}) => {
    try {
      const response = await api.sendMessage({
        chat_id: null,
        sender: `${memKey}|${promptData?.mem_key}`,
        msg_div: 'user',
        contents: null,
        mem_key: memKey,
      });
      // console.log(response);
      navigate('/chat/chatLayout', { state: { tabId: 'normal' } });
    } catch (error) {
      console.error('Error sending message:', error);
      message.error('메시지 전송에 실패했습니다.');
    }
  };

  // 인기 프롬프트 리스트 조회
  const getPopularList = async () => {
    if (promptData.mem_key) {
      const { data } = await api.getProfilePromptList({
        cate_cd: promptData.cate_cd,
        mem_key: promptData.mem_key,
        filter_sort: 'popular',
        offset: 0,
        limit: 12,
        filter_text: '',
      });
      if (!data) return;
      setPopularList(data.items);
    }
  };

  // 최신 프롬프트 리스트 조회
  const getRecentList = async () => {
    if (promptData.mem_key) {
      const { data } = await api.getProfilePromptList({
        cate_cd: promptData.cate_cd,
        mem_key: promptData.mem_key,
        filter_sort: 'new',
        offset: 0,
        limit: 12,
        filter_text: '',
      });
      if (!data) return;
      setRecentList(data.items);
    }
  };

  // 프롬프트 상태에 따른 클래스명
  const getPrptStatClassName = (prptStat) => {
    return (
      {
        '00': 'done',
        '01': 'disable',
        10: 'request',
        20: 'ok',
        30: 'on',
        98: 'rejection',
        99: 'off',
      }[prptStat] || ''
    );
  };

  // 카테고리별 이미지 가져오기
  const getCateImage = (cateCd, active = false) => {
    return {
      CATE001: imageIcon,
      CATE002: videoIcon,
      CATE003: musicIcon,
      CATE004: textIcon,
    }[cateCd];
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (prompt) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: prompt?.prpt_id, view_type: 'V' });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(PATH.PROMPT_DETAIL, { state: { prpt_id: prompt?.prpt_id } });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 비디오/음악 플레이어 열기
  const onOpenPlayer = async (prompt) => {
    try {
      if (!prompt) return;
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        if (prompt.cate_cd === 'CATE002') window.open(`${PATH.VIDEO_PLAYER}?prpt_id=${encodeURI(btoa(prompt?.prpt_id))}`, POPUP_VIDEO_PLAYER, 'width=700,height=400');
        if (prompt.cate_cd === 'CATE003') {
          // 플레이리스트에 곡 추가
          const requestMusic = {
            mem_key: memKey,
            prpt_id: prompt?.prpt_id,
            prpt_title: prompt?.prpt_title,
            prpt_path: prompt?.music_org_link,
          };
          await api.addPlaylistMusic([requestMusic]);
          window.open(PATH.MUSIC_PLAYER, POPUP_MUSIC_PLAYER, 'width=375,height=700');
        }
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    // 이노페이 SDK 스크립트 로드
    const script = document.createElement('script');
    script.src = 'https://pg.innopay.co.kr/ipay/js/innopay-2.0.js';
    script.charset = 'utf-8';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // 컴포넌트 언마운트 시 스크립트 제거
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (descTextRef.current) {
      const height = descTextRef.current.scrollHeight;
      const maxHeight = width < 1500 ? 210 : 395;
      setShowMore((prev) => ({ ...prev, desc: height > maxHeight }));
    }

    if (guideTextRef.current) {
      const height = guideTextRef.current.scrollHeight;
      const maxHeight = 50;
      setShowMore((prev) => ({ ...prev, guide: height > maxHeight }));
    }

    if (prptTextRef.current) {
      const height = prptTextRef.current.scrollHeight;
      const maxHeight = 100;
      setShowMore((prev) => ({ ...prev, prpt: height > maxHeight }));
    }

    getPopularList();
    getRecentList();
  }, [promptData, width]);

  useEffect(() => {
    getCommonCode(); // 공통코드 조회
    getCommonCodeRejectRsn();
    listBadge();
    listAiModel();
    if (validateSession({ authType: AUTH_LOGIN })) {
      // 로그인된 경우 로그인 사용자 정보도 함께 보냄
      getPromptDetail({ prpt_id: prpt_id, mem_key: memKey });
      searchReview({ prpt_id: prpt_id, mem_key: memKey });
      getPromptLike(); // 좋아요
      listSavedMusic(); // 음악, 보관함
    } else {
      getPromptDetail({ prpt_id: prpt_id });
      searchReview({ prpt_id: prpt_id });
    }
  }, [state, auth]);

  return (
    <>
      <article className="flexColCenter" id="detail">
        <div className="top flexColCenter">
          <div className="rsWrapper flexRowBetween">
            {promptData?.cate_cd != 'CATE004' && (
              <div className="left flexColCenter">
                <div className="user flexRowBetween">
                  <div className="flexRowCenter">
                    {/* <img src={promptData?.icon_path} /> */}
                    <p>{promptData?.ai_model_nm}</p>
                  </div>
                  {isMobile && !isWriter && (
                    <div className="flexRowCenter">
                      <button onClick={() => goMarketPlace(promptData?.cate_cd)}>
                        <img
                          style={{ width: '23px', height: '25px' }}
                          src={mobileList} />
                      </button>
                      <div className="siren"
                        style={{ width: '24px', height: '24px' }}
                      >
                        <img src={promptData?.prpt_bad == 'Y' ? activeSirenIcon : sirenIcon} onClick={openRequestBadModal} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="box16">
                  <div className={promptData?.cate_cd == 'CATE001' ? 'imgScroll flexColCenter scroll' : 'imgBox flexColCenter scroll'}>
                    <div className="flexColCenter">
                      {promptData?.cate_cd == 'CATE001' && (
                        <div className="activeSiren flexRowStart">
                          <div className="flexColCenter" onClick={() => changeViewImage(promptData?.thum_path)}>
                            <img src={promptData?.thum_path} />
                          </div>
                          {fileList.map((file) => (
                            <div className="flexColCenter" key={file.file_seq} onClick={() => changeViewImage(file?.file_dwn_path)}>
                              <img src={file?.file_dwn_path} />
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="thumbnail flexColCenter">{promptData?.cate_cd == 'CATE001' ? <Image src={viewImage} /> : <img src={promptData?.thum_path} />}</div>
                    </div>
                  </div>
                </div>
                {promptData?.cate_cd == 'CATE003' && (
                  <div id="musicBox">
                    <AudioPlayer
                      ref={playerRef}
                      src={fileList.length > 0 ? fileList[0].file_dwn_path : null}
                      autoPlay={false}
                      customIcons={{
                        pause: <img src={playingIcon} />,
                        play: <img src={playIcon3} />,
                        rewind: <img src={before10Seconds} />,
                        forward: <img src={after10Seconds} />,
                      }}
                      progressJumpSteps={{ backward: 10000, forward: 10000 }}
                      customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME, <div className="separator">/</div>, RHAP_UI.DURATION]}
                      // onEnded={() => {
                      //   playerRef.current.audio.current.pause();
                      // }}
                      onPlay={handleMusicPlay}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="right flexColEnd" style={{ width: promptData?.cate_cd == 'CATE004' ? '100%' : '' }}>
              {/* <div className="mobileUser flexRowStart">
                <div className="userIcon flexColCenter cursor_link" onClick={goProfile}>
                  <img src={writerProfile?.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />
                </div>

                <div className="flexColStart">
                  <div className="grade flexRowStart">
                    <div className="flexColCenter">{writerProfile?.plan_nm}</div>
                    <h2 onClick={goProfile} className="cursor_link">
                      {writerProfile?.mem_nick}
                    </h2>
                  </div>
                </div>
              </div> */}

              {(!isMobile || promptData?.cate_cd === 'CATE004') && (
                <div className="flexRowBetween orderFirst">
                  <div>{promptData?.cate_cd === 'CATE004' && promptData?.ai_model_nm && <p className="aiModelInfo">{promptData?.ai_model_nm}</p>}</div>
                  {!isWriter && (
                    <div className="flexRowStart" style={{ width:'auto'}}>
                      <button onClick={() => goMarketPlace(promptData?.cate_cd)}>
                        <img
                          style={{ width: '23px', height: '25px' }}
                          src={mobileList} />
                      </button>
                      <div className="siren">
                        <img src={promptData?.prpt_bad == 'Y' ? activeSirenIcon : sirenIcon} onClick={openRequestBadModal} />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <h1>{promptData?.prpt_title}</h1>
              <div className="user flexRowStart">
                <div className="userIcon flexColCenter cursor_link" onClick={goProfile}>
                  <img src={writerProfile?.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />
                </div>

                <div className="flexColStart">
                  <div className="grade flexRowStart">
                    {/*<div className="flexColCenter"></div>*/}
                    <span className={`badge ${badge_map[writerProfile?.plan_cd] || ''}`}>{writerProfile?.plan_nm}</span>
                    <h2 onClick={goProfile} className="cursor_link">
                      {writerProfile?.mem_nick}
                    </h2>
                  </div>

                  <div className="flexRowStart" style={{ gap: '10px' }}>
                    <div className="classRank">
                      <Dropdown
                        trigger="click"
                        getPopupContainer={() => document.getElementsByClassName('classRank')[0]}
                        menu={{
                          items: cateRankList?.map((rank, index) => {
                            return {
                              key: index,
                              label: (
                                <div className="flexRowStart">
                                  <img src={getCateImage(rank?.agg_cate)} alt="cate icon" />
                                  <p>#{koKRFormat(rank?.agg_rank) || 'N/A'}</p>
                                </div>
                              ),
                              onClick: () =>
                                navigate(PATH.PROFILE_PAGE, {
                                  state: {
                                    mem_email: writerProfile?.mem_email,
                                    mem_key: writerProfile?.mem_key,
                                    ranking: 'true',
                                    cateCd: rank?.agg_cate,
                                  },
                                }),
                            };
                          }),
                        }}
                      >
                        <div className="flexRowStart">
                          <img src={getCateImage(cateRankList[0]?.agg_cate, true) || ''} alt="cate icon" />
                          <p>#{koKRFormat(cateRankList[0]?.agg_rank) || 'N/A'}</p>
                          <img src={downArrow} alt="down arrow" />
                        </div>
                      </Dropdown>
                    </div>

                    <div className="major">
                      {getMemberBadge(writerProfile?.bdg_cd).map((badge, index) => (
                        <Tooltip title={badge?.bdg_nm}>
                          <img key={index} src={badge?.icon_path} alt="icon" />
                        </Tooltip>
                      ))}
                    </div>
                  </div>

                  <div className="user flexColStart">
                    <div className="majorModel">
                      <span className="title">전문 AI분야</span>
                      {writerProfile?.ai_fl_cd ? (
                        <>
                          {getMemberAiModel(writerProfile?.ai_fl_cd).map((aiModel, index) => (
                            <span key={index} className="">
                              {aiModel?.cd_nm}
                            </span>
                          ))}
                        </>
                      ) : (
                        <>
                          {writerProfile?.mem_key === memKey ? (
                            <span className="me" onClick={() => navigate(PATH.PROFILE_PAGE, { state: { mem_email: memEmail, mem_key: memKey, profileDetail: 'true' } })}>
                              전문 AI 분야를 설정하세요
                            </span>
                          ) : (
                            <span>없음</span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="userInfo flexRowBetween">
                <div className="flexRowStart">
                  {promptData?.cate_cd === 'CATE003' ? (
                    <div className="countNum play">
                      플레이수<span>{formatNumber(promptData?.play_cnt)}</span>
                    </div>
                  ) : (
                    <div className="countNum view">
                      {' '}
                      조회수<span>{formatNumber(promptData?.view_cnt)}</span>
                    </div>
                  )}
                  <div>
                    {/* <Rate
                      value={promptData?.score_avg || 0} // 점수를 기반으로 별 표시
                      disabled
                      allowHalf // 소수점반영
                    />

                    <p>
                      (<span>{reviews.length}</span>)
                    </p> */}
                    {memKey && (
                      <div className="iconBox">
                        <div className="icon" id={isLike ? 'active' : ''} onClick={() => toggleLikes(isLike ? 'delete' : 'add')}>
                          <img src={activeHeartIcon} className="activeIcon" />
                          <img src={heartIcon} />
                        </div>
                        {promptData?.cate_cd == 'CATE003' && (
                          <div className="icon" id={isSaveMusic ? 'active' : ''} onClick={isSaveMusic ? deleteMyMusic : addMyMusic}>
                            <img src={activeSaveIcon} className="activeIcon" />
                            <img src={saveIcon} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {/* 프롬프트 상태: 관리자 또는 작성자만 볼 수 있음 */}
                {(isAdmin(memAuth) || isWriter) && (
                  <div className="flexRowEnd">
                    {/* 프롬프트 상태값이 있어야 노출됨 */}
                    {promptData?.prpt_stat && (
                      <span className={`approval ${getPrptStatClassName(promptData?.prpt_stat)}`}>
                        {/* 판매거절 상태일때만 버튼처리 */}
                        {promptData?.prpt_stat === '98' ? (
                          <button onClick={openRejectReasonModal}>
                            <img src={previewReason} />
                            {`${promptData?.prpt_stat_nm} 사유`}
                          </button>
                        ) : (
                          <>{promptData?.prpt_stat_nm}</>
                        )}
                      </span>
                    )}
                  </div>
                )}
              </div>

              <ul className="genreInfo">
                <li>
                  <span>{promptData?.cate_nm}</span>
                </li>
                {promptData?.genre_nm ? (
                  <li>
                    <span>{promptData?.genre_nm}</span>
                  </li>
                ) : null}
                {promptData?.sub_genre_nm ? (
                  <li>
                    <span>{promptData?.sub_genre_nm}</span>
                  </li>
                ) : null}
              </ul>

              <div className="mobilePrice flexRowBetween">
                {promptData?.cate_cd !== 'CATE003' ? promptData?.sale_amt === 0 ? <h1>무료</h1> : <h1>₩{formatNumber(promptData?.sale_amt)}</h1> : <h1></h1>}

                {(promptData?.cate_cd === 'CATE003' && (isAdmin(memAuth) || isWriter || isPaidUser(memPlanCd))) ||
                (promptData?.cate_cd === 'CATE001' && (isAdmin(memAuth) || isWriter || isPurchases)) ? (
                  <div className="downloadBox">
                    <button id="purpleBtn" onClick={downLoadFile}>
                      <img src={downliadIcon} />
                      <p>다운로드</p>
                    </button>
                  </div>
                ) : (
                  <>
                    {promptData?.cate_cd === 'CATE003' && !isAdmin(memAuth) && !isWriter && !isPaidUser(memPlanCd) && memKey ? (
                      <Tooltip title="다운로드는 유료 플랜만 가능합니다.">
                        <div className="downloadBox disabled">
                          <button id="purpleBtn">
                            <img src={downliadIcon} />
                            <p>다운로드</p>
                          </button>
                        </div>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
              <div className="content flexColStart">
                <div className="flexRowStart typeBox">
                  {/* {promptData?.cate_nm && <div className="type flexColCenter">{promptData?.cate_nm}</div>}
                  {promptData?.genre_nm && <div className="type flexColCenter">{promptData?.genre_nm}</div>}
                  {promptData?.sub_genre_nm && <div className="type flexColCenter">{promptData?.sub_genre_nm}</div>}
                  {promptData?.prpt_sample && <div className="type flexColCenter">{promptData?.prpt_sample}</div>}
                  {promptData?.prpt_step && <div className="type flexColCenter">{promptData?.prpt_step}</div>}
                  {promptData?.prpt_seed && <div className="type flexColCenter">{promptData?.prpt_seed}</div>}
                  {promptData?.clip_gdc && <div className="type flexColCenter">{promptData?.clip_gdc}</div>} */}
                </div>
                <div
                  className="text flexColStart"
                  style={{
                    minHeight: descMoreFlag ? `auto` : width < 1500 ? `210px` : `395px`,
                  }}
                >
                  {promptData?.tag && (
                    <ul className="tagBox">
                      {promptData?.tag?.split(',').map((tag, index) => (
                        <li key={index}>#{tag}</li>
                      ))}
                    </ul>
                  )}

                  {promptData?.cate_cd !== 'CATE003' ? (
                    <>
                      <Input.TextArea value={promptData?.prpt_desc} autoSize disabled />
                    </>
                  ) : (
                    <Collapse
                      className="prptDetailAccordion"
                      ghost
                      defaultActiveKey={['prpt_desc']}
                      items={[
                        {
                          key: 'prpt_desc',
                          label: '소개',
                          children: <Input.TextArea value={promptData?.prpt_desc || '없음'} autoSize disabled />,
                        },
                        {
                          key: 'prpt_lyrics',
                          label: '가사',
                          children: <Input.TextArea value={promptData?.prpt_lyrics || '없음'} autoSize disabled />,
                          // collapsible: isAdmin(memAuth) || isWriter || isPaidUser(memPlanCd) ? 'header' : 'disabled',
                          style: { display: isAdmin(memAuth) || isWriter || isPaidUser(memPlanCd) ? 'block' : 'none' },
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
              <button id="greyBtn5" onClick={() => goMarketPlace(promptData?.cate_cd)}>
                <img src={marketIcon} />
                <p>리스트로 돌아가기</p>
              </button>

              <div className="priceBox flexRowBetween">
                {promptData?.cate_cd !== 'CATE003' ? promptData?.sale_amt === 0 ? <h1>무료</h1> : <h1>₩{formatNumber(promptData?.sale_amt)}</h1> : <h1></h1>}
                {(promptData?.cate_cd === 'CATE003' && (isAdmin(memAuth) || isWriter || isPaidUser(memPlanCd))) ||
                (promptData?.cate_cd === 'CATE001' && (isAdmin(memAuth) || isWriter || isPurchases)) ? (
                  <div className="downloadBox">
                    <button id="purpleBtn" onClick={downLoadFile}>
                      <img src={downliadIcon} />
                      <p>다운로드</p>
                    </button>
                  </div>
                ) : (
                  <>
                    {promptData?.cate_cd === 'CATE003' && !isAdmin(memAuth) && !isWriter && !isPaidUser(memPlanCd) && memKey ? (
                      <Tooltip title="다운로드는 유료 플랜만 가능합니다.">
                        <div className="downloadBox disabled">
                          <button id="purpleBtn">
                            <img src={downliadIcon} />
                            <p>다운로드</p>
                          </button>
                        </div>
                      </Tooltip>
                    ) : (
                      <>
                        {['CATE001', 'CATE004'].includes(promptData?.cate_cd) && !isAdmin(memAuth) && !isWriter && !isPurchases && memKey ? (
                          <div className="flexRowCenter">
                            {promptData?.cate_cd !== 'CATE003' ? (
                              <>
                                <CartButton onCart={addToCart} />
                                <DirectBuyButton onBuy={openReceiptModal} />
                              </>
                            ) : null}
                          </div>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="rsWrapper flexColCenter">
          {/* 프롬프트 / 프롬프트 활용 가이드 / 판매자 문의 */}
          {/* 이미지 or 텍스트 프롬프트이면서, 관리자 or 작성자 or 구매자만 볼 수 있음 */}
          {['CATE001', 'CATE004'].includes(promptData?.cate_cd) && (isAdmin(memAuth) || isWriter || isPurchases) && (
            <>
              <div className="prompt flexColStart">
                <h1>프롬프트</h1>
                <div className="flexColStart">
                  <Input.TextArea ref={prptTextRef} value={promptData?.test_prpt || ''} style={{ resize: `none`, color: `#3e3d53` }} autoSize={{ minRows: 5, maxRows: 15 }} />
                  <div className="btnBox flexRowEnd">
                    {showMore.prpt && (
                      <div className="flexRowCenter" onClick={() => setPrptMoreFlag(!prptMoreFlag)}>
                        <img src={randomIcon} />
                        <p>&nbsp; 펼치기</p>
                      </div>
                    )}

                    <CopyButton onCopy={copyText} />
                  </div>
                </div>
              </div>

              <div className="rsWrapper flexColCenter promptGuide">
                {promptData?.prpt_remark && (
                  <div
                    className="flexColStart tip"
                    style={{
                      height: guideMoreFlag ? `auto` : showMore.guide ? `100px` : `auto`,
                    }}
                  >
                    <div className="flexColStart">
                      <h1>프롬프트 활용 가이드</h1>
                      <div className="text">
                        <p ref={guideTextRef}>{promptData?.prpt_remark}</p>
                      </div>
                    </div>
                  </div>
                )}
                {showMore.guide && (
                  <div className="more flexColEnd" onClick={() => setGuideMoreFlag(!guideMoreFlag)}>
                    <p> 더보기</p>
                  </div>
                )}
              </div>

              {/* 작성자는 본인에게 문의할 수 없음  // 모바일 영역 퍼블로 분리 한것을 pc화면 활용 */}
              {/* {!isWriter && (
                <div className="mobileQuestion flexColCenter">
                  <p>프롬프트 사용에 어려움이 있나요?</p>
                  <button
                    id="darkGrey2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleSendMessage();
                    }}
                  >
                     <img src={questionIcon} />
                    <img src={whiteQuestionIcon} className="hoverIcon" /> 
                    <p>판매자에게 문의하기</p>
                  </button>
                </div>
              )} */}
            </>
          )}

          {(isAdmin(memAuth) || isWriter) && (
            <div className="btnArea">
              {/* 판매중/판매중지: 관리자/작성자만 할 수 있음 */}
              {(isAdmin(memAuth) || isWriter) && (
                <>
                  {/* 판매중일때 판매중지 버튼 활성화 */}
                  {promptData?.prpt_stat === '30' && (
                    <span className="approval off" onClick={() => requestOnOff('99', promptData?.mem_key)}>
                      <button>
                        <p>판매중지</p>
                      </button>
                    </span>
                  )}
                  {/* 판매중지일때 판매중 버튼 활성화 */}
                  {promptData?.prpt_stat === '99' && (
                    <span className="approval ok" onClick={() => requestOnOff('30', promptData?.mem_key)}>
                      <button>
                        <p>판매하기</p>
                      </button>
                    </span>
                  )}
                </>
              )}

              {/* 판매거절: 관리자만 할 수 있음, 판매요청일때 활성화 */}
              {isAdmin(memAuth) && promptData?.prpt_stat === '10' && (
                <span className="approval rejection" onClick={openRejectReasonModal}>
                  <button>
                    <p>판매거절</p>
                  </button>
                </span>
              )}

              {/* 수정/삭제: 작성자만 할 수 있음 */}
              {isWriter && (
                <>
                  <span className="approval done" onClick={() => navigate(PATH.SALES_PROMPT_HOME, { state: { prpt_id } })}>
                    <button>
                      <p>수정</p>
                    </button>
                  </span>
                  <span className="approval rejection" onClick={() => requestDelete(promptData?.prpt_stat)}>
                    <button>
                      <p>삭제</p>
                    </button>
                  </span>
                </>
              )}
            </div>
          )}

          {/* 리뷰 남기기 */}
          {/* 관리자 or 동영상/이미지/텍스트 이고, 작성자가 아니면서 구매자인 경우 or 음악 이고, 작성자가 아니면서 구독자인 경우  */}
          {(isAdmin(memAuth) || (promptData?.cate_cd !== 'CATE003' && !isWriter && isPurchases) || (promptData?.cate_cd === 'CATE003' && !isWriter && isPaidUser(memPlanCd))) && (
            <Form form={reviewForm} className="reviewCreate flexRowBetween" onFinish={handleSaveReview}>
              {!isReview && (
                <>
                  {/* <div className="review flexColStart">
                    <p>별점을 남겨주세요</p>

                    <div className="flexColStart">
                      <Form.Item noStyle name="score">
                        <Rate style={{ fontSize: `26px` }} />
                      </Form.Item>
                    </div>
                  </div> */}

                  <div className="create flexColStart">
                    <p>리뷰를 남겨주세요</p>
                    <div className="flexRowBetween">
                      <Form.Item noStyle name="contents">
                        <Input.TextArea className="input mobileInput" placeholder="작품에 대한 리뷰를 남겨 주세요." />
                      </Form.Item>

                      <button id="greyBtn">저장</button>
                    </div>
                  </div>
                </>
              )}

              {/* 판매자 문의 */}
              {/* 이미지 or 텍스트 프롬프트 이고, 작성자가 아니면서, 관리자 or 구매자만 볼 수 있음 */}
              {['CATE001', 'CATE004'].includes(promptData?.cate_cd) && !isWriter && (isAdmin(memAuth) || isPurchases) && (
                <div className="question flexColCenter">
                  <p>프롬프트 사용에 어려움이 있나요?</p>
                  <button
                    id="darkGrey2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleSendMessage();
                    }}
                  >
                    {/* <img src={questionIcon} />
                    <img src={whiteQuestionIcon} className="hoverIcon" /> */}
                    <p className="icon ques">판매자에게 문의하기</p>
                  </button>
                </div>
              )}
            </Form>
          )}

          <div className="reviewList flexColStart">
            {reviews.slice(0, 5).map((review) => (
              <div className={`list flexRowBetween ${review.re_cnt > 0 || reviewFlag[review.seq] ? 'answer' : ''}`} key={review.seq}>
                <div className="userReview flexRowStart">
                  <img src={review.score_mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />

                  <div className="flexColStart">
                    <div className="top flexRowBetween">
                      <div className="flexRowCenter">
                        <img src={review.score_mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />
                        <p>{review.score_mem_nick}</p>
                        {/* <Rate className="mobileRate" style={{ fontSize: `10px` }} value={review.score} disabled={true} /> */}
                      </div>

                      <p>{formatEncodingDate(review.fir_dt)}</p>
                      {review.re_cnt === '0' && promptData?.mem_key === memKey && (
                        <button id="darkGreyBorder" onClick={() => handleReply(review.seq)}>
                          답글
                        </button>
                      )}
                    </div>

                    <div className="rateBox flexRowStart">
                      {/* <Rate value={review.score} disabled={true} />
                      <div className="line"></div> */}
                      <p>{formatEncodingBoardDate(review.fir_dt)}</p>
                    </div>

                    <p className="content">{review.contents}</p>
                    {review.re_cnt === '0' && promptData?.mem_key === memKey && (
                        <button id="darkGreyBorder" className="mobileBtn" onClick={() => handleReply(review.seq)}>
                          답글
                        </button>
                      )}
                  </div>
                </div>

                {review.re_cnt > 0 ? (
                  <div className="answer flexRowBetween">
                    <div className="flexRowBetween">
                      <div className="flexRowCenter">
                        <h1>{review.re_mem_nm}</h1>
                        <p>{formatEncodingBoardDate(review.rep_fir_dt)}</p>
                      </div>
                    </div>

                    <div className="flexColStart content">{review.rep_contents}</div>
                  </div>
                ) : reviewFlag[review.seq] ? (
                  <div className="answer flexRowBetween">
                    <Input.TextArea className="input mobileInput" placeholder="답글을 남겨주세요." onChange={(e) => handleReplyChange(review.seq, e.target.value)} />
                    <button id="greyBtn" onClick={() => handleSaveReply(review)}>
                      저장
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>

          {(isWriter || isAdmin(memAuth) || isPurchases || (promptData?.cate_cd === 'CATE003' && isPaidUser(memPlanCd)) || reviews.length > 0) && (
            <div className="allReview flexColStart">
              <p onClick={reviewToggle}>
                전체 리뷰 보기 <ArrowRightOutlined />
              </p>
            </div>
          )}
        </div>

        <div className="promptThumList forceWidth">
          <SliderCardPrompt
            title="인기 컨텐츠"
            titleImg={partyIcon}
            promptList={popularList}
            openPlayer={onOpenPlayer}
            moveToPromptDetailPage={moveToDetailPage}
            moveToUserDetailPage={() => navigate(PATH.PROFILE_PAGE, { state: { mem_email: writerProfile?.mem_email, mem_key: writerProfile?.mem_key } })}
          />
          <ListCardPrompt
            title="최신 컨텐츠"
            titleImg={newIcon}
            promptList={recentList}
            openPlayer={onOpenPlayer}
            moveToPromptDetailPage={moveToDetailPage}
            moveToUserDetailPage={() => navigate(PATH.PROFILE_PAGE, { state: { mem_email: writerProfile?.mem_email, mem_key: writerProfile?.mem_key } })}
          />
        </div>
      </article>
      <ReceiptModal
        visible={isReceiptModalVisible}
        amount={promptData?.sale_amt}
        onClose={closeReceiptModal}
        setIsAutomaticPayment={handleOpenPayment}
        isCoupon={false}
        paymentAmount={promptData?.sale_amt}
        setPaymentAmount={(amount) => {
          setPromptData((prev) => ({
            ...prev,
            sale_amt: amount,
          }));
        }}
      />
      {/* 전체리뷰보기 모달 (웹) */}
      <ListReview isOpen={reviewModal} onClose={reviewToggle} promptInfo={promptData} reviewList={reviews} />

      {/*전체리뷰 보기 모달 (모바일)*/}
      <ListReviewMobile
        isOpen={reviewModal2}
        onClose={reviewToggle}
        promptInfo={promptData}
        reviewList={reviews}
        onReply={handleReply}
        onSave={handleSaveReply}
        onChangeReply={handleReplyChange}
        reviewFlag={reviewFlag}
      />

      {/*신고하기 모달*/}
      <RequestBad isOpen={requestBadModal} onClose={closeRequestBadModal} onOk={requestBadPrompt} selectOptions={badRsnList} />

      {/* 판매거절 모달 */}
      <RejectReason isOpen={rejectReasonModal} onClose={closeRejectReasonModal} onOk={requestReject} promptInfo={promptData} selectOptions={rejectRsnList} />
    </>
  );
};

export default PromptDetail;
