import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Marketplace from '@pages/musics/MusicMarketplace';
import Tabs from '@components/Tabs';
import useWidth from '@hooks/useWidth';

const MusicHome = () => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState('marketplace');
  const [isTabChanged, setIsTabChanged] = useState(true); // 탭 변경을 막기 위한 상태
  const width = useWidth();

  const tabs = [
    { id: 'marketplace', label: '음원', component: <Marketplace /> },
    { id: 'space', label: width > 700 ? '음원공간' : '음원공간', component: <></> },
    { id: 'album', label: '앨범', component: <></> },
    { id: 'artist', label: '아티스트', component: <></> },
    { id: 'history', label: width > 700 ? '명예의 전당' : '명예의 전당', component: <></> },
  ];

  const handleTabClick = (key) => {
    setIsTabChanged(true);
    if (isTabChanged) {
      setActiveKey(key);
    }
  };

  useEffect(() => {
    if (location.state?.space === 'true') {
      setActiveKey('space');
    } else if (location.state?.album === 'true') {
      setActiveKey('album');
    } else if (location.state?.artist === 'true') {
      setActiveKey('artist');
    } else if (location.state?.history === 'true') {
      setActiveKey('history');
    } else {
      setActiveKey('marketplace');
    }
  }, [location.state]);

  return <Tabs articleId="music" tabs={tabs} initialTab={activeKey} onTabClick={handleTabClick} />;
};

export default MusicHome;
