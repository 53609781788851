import dayjs from 'dayjs';
import JSEncrypt from 'jsencrypt';
import { message, Modal } from 'antd';
import AhoCorasick from 'ahocorasick';
// path
import * as PATH from '@routes/pathName';
import * as api from '@api/index';
// icon
import { IMAGES } from '@utils/image';

// const
import { AUTH_LOGIN, AUTH_ADMIN, AUTH_BEGINNER, AUTH_BASIC, AUTH_PRO, FORBIDDEN_WORD_KEY } from '@utils/constants';
import React, { createContext, useContext, useRef, useState } from 'react';

const WEB_HOST = process.env.REACT_APP_WEB_HOST;
const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

export const showAlert = (value, flag) => {
  const formattedValue = value.replace(/<br\s*\/?>/gi, '\n');

  if (!flag) {
    flag = 'warning';
  }
  if (flag === 'warning') {
    Modal.warning({ content: <pre>{formattedValue}</pre>, okText: '확인' });
  }
  if (flag === 'success') {
    Modal.success({ content: <pre>{formattedValue}</pre>, okText: '확인' });
  }
  if (flag === 'error') {
    Modal.error({ content: <pre>{formattedValue}</pre>, okText: '확인' });
  }
  if (flag === 'info') {
    Modal.info({ content: <pre>{formattedValue}</pre>, okText: '확인' });
  }
};

window.alert = (value, flag) => {
  return showAlert(value, flag);
};

export const showConfirm = (value, type) => {
  return new Promise((resolve) => {
    const formattedValue = value.replace(/<br\s*\/?>/gi, '\n');

    // 삭제용 confirm 창
    if (type === 'delete') {
      Modal.confirm({
        icon: <img src={IMAGES.DELETE_CONFIRM_ICON} alt="" />,
        content: <pre>{formattedValue}</pre>,
        okButtonProps: {
          style: { order: 0, background: '#f34144' },
        },
        cancelButtonProps: {
          style: { order: 1 },
        },
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
        mask: false,
        getContainer: () => document.getElementsByTagName('section')[0],
        okText: '확인',
        cancelText: '취소',
      });
    }
    // 기본 confirm 창
    else {
      Modal.confirm({
        content: <pre>{formattedValue}</pre>,
        okButtonProps: {
          style: { order: 0 },
        },
        cancelButtonProps: {
          style: { order: 1 },
        },
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
        mask: false,
        getContainer: () => document.getElementsByTagName('section')[0],
        okText: '확인',
        cancelText: '취소',
      });
    }
  });
};

window.confirm = (value, type) => {
  return showConfirm(value, type);
};

export const dayFormat = (date) => {
  return date ? dayjs(date)?.format('YYYY-MM-DD') : date;
};

export const dayFormatYearMonth = (date) => {
  return date ? dayjs(date)?.format('YYYY-MM') : date;
};

export const dayFormatDot = (date) => {
  return date ? dayjs(date)?.format('YYYY.MM.DD') : date;
};

export const dayFormatKR = (date) => {
  return date ? dayjs(date)?.format('YYYY년 MM월 DD일') : date;
};

export const secondsFormat = (date) => {
  return date ? dayjs(date)?.format('YYYY-MM-DD HH:mm:ss') : date;
};

export const secondsCompactFormat = (date) => {
  return date ? dayjs(date)?.format('YYYYMMDDHHmmss') : date;
};

export const msTimestampFormat = (date) => {
  return date ? dayjs(date)?.format('x') : date;
};

export const isEmpty = (value) => {
  return value === null || value === undefined || value === '';
};

export const encryptRSA = (str) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_KEY);
  return encrypt.encrypt(str);
};

// 디바운싱
export const useDebounce = (callback, delay) => {
  let timer;
  return (...args) => {
    //실행한 함수(setTimeout())취소
    clearTimeout(timer);
    //delay가 지나면 callback 함수 실행
    timer = setTimeout(() => callback(...args), delay);
  };
};

// 관리자 권한인지 아닌지
export const isAdmin = (value) => {
  return value === 'R002';
};

// 유료회원인지 아닌지
export const isPaidUser = (value) => {
  return ['PLAN002', 'PLAN003'].includes(value);
};

// 탈퇴한 유저가 맞는지 아닌지
export const isCanceledUser = (value) => {
  return value === '99';
};

// Select Box 리스트용, [{ label, value }] 형태로 만들기
export const makeSelectBoxList = (items = [], labelField, valueField) => {
  const result = [];
  if (items.length > 0) {
    const newArray = items.map((item) => {
      return { label: item[labelField], value: item[valueField] };
    });
    result.push(...newArray);
  }
  return result;
};

// 데이터 포멧터, { key: value } 형태로 만들기
export const makeDataFormat = (items = [], formatedValueField, valueField) => {
  const result = {};
  if (items.length > 0) {
    items.map((item) => {
      const key = item[valueField];
      const value = item[formatedValueField];
      result[key] = value;
      return result;
    });
  }
  return result;
};

// 천단위 콤마
export const koKRFormat = (value) => {
  if (!Number.isInteger(value)) return value;
  return value.toLocaleString('ko-KR');
};

export const phoneFormat = (number) => {
  if (!number) return '';
  const cleaned = ('' + number).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  return match ? `${match[1]}-${match[2]}-${match[3]}` : number;
};

export const formatMessageDate = (dateString) => {
  const messageDate = new Date(dateString);
  const today = new Date();
  const isToday = messageDate.toDateString() === today.toDateString();
  const isYesterday = messageDate.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString();
  if (isToday) {
    return messageDate.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit' });
  } else if (isYesterday) {
    return '어제';
  } else {
    return messageDate.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
};
export const formatAlramDate = (dateString) => {
  const messageDate = new Date(dateString);
  const today = new Date();
  const isToday = messageDate.toDateString() === today.toDateString();
  const isYesterday = messageDate.toDateString() === new Date(today.setDate(today.getDate() - 1)).toDateString();

  const date = messageDate.toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const time = messageDate.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  return (
    <>
      <p>{date}</p>
      <p>{time}</p>
    </>
  );
};

export function groupMessagesByDate(messages) {
  const groups = [];
  let currentDate = null;

  messages.forEach((message) => {
    const messageDate = new Date(message.lst_dt);
    const dateString = messageDate.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    if (dateString !== currentDate) {
      currentDate = dateString;
      groups.push({ type: 'date', date: dateString });
    }
    groups.push({ type: 'message', data: message });
  });
  return groups;
}

export function formatEncodingDate(dateString) {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options);
}

export function formatNumber(value) {
  if (!isNaN(value)) {
    return Number(value).toLocaleString(); // 3자리마다 , 추가
  }
  return value; // 숫자가 아닐 경우 원래 값을 반환
}

export const getLocalStorage = (storageKey, field) => {
  /**
   * @param {string} storageKey - localStorage에서 가져올 키 (예: 'prptbk-token').
   * @param {string} field - JSON 데이터에서 찾을 필드 이름 (예: 'mem_key').
   * @returns {*} 지정된 필드 값 또는 undefined.
   */
  try {
    const storedData = localStorage.getItem(storageKey);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData[field];
    }
  } catch (error) {
    console.error(`Error parsing localStorage data for key "${storageKey}":`, error);
  }
  return null;
};

export const getlocalStorage = (storageKey, field) => {
  /**
   * @param {string} storageKey - localStorage에서 가져올 키 (예: 'prptbk-token').
   * @param {string} field - JSON 데이터에서 찾을 필드 이름 (예: 'mem_key').
   * @returns {*} 지정된 필드 값 또는 undefined.
   */
  try {
    const storedData = localStorage.getItem(storageKey);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData[field];
    }
  } catch (error) {
    console.error(`Error parsing localStorage data for key "${storageKey}":`, error);
  }
  return null;
};

export const sessionErrorConfirm = (errorCode) => {
  switch (errorCode) {
    case 'ERR701':
      window.location.href = `${WEB_HOST}${PATH.LOGIN}`;
      break;
    case 'ERR702':
      window.confirm('관리자만 사용가능한 서비스입니다.<br/>관리자로 전환 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    case 'ERR703':
      window.confirm('플랜 구독이 필요한 서비스입니다.<br/>구독 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    case 'ERR704':
      window.confirm('베이직 플랜 이상만 사용가능한 서비스입니다.<br/>플랜을 업그레이드 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    case 'ERR705':
      window.confirm('프로 플랜만 사용가능한 서비스입니다.<br/>플랜을 업그레이드 하시겠습니까?').then((result) => {
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.PROFILE_PAGE}`;
        } else return;
      });
      break;
    case 401:
      window.confirm('세션이 종료되었습니다.<br/>다시 로그인 하시겠습니까?').then((result) => {
        localStorage.clear();
        if (result) {
          window.location.href = `${WEB_HOST}${PATH.LOGIN}`;
        } else {
          window.location.href = `${WEB_HOST}`;
        }
      });
      break;
    case 405: // sessionCheck 결과
      localStorage.clear();
    default:
      break;
  }
};

// API를 호출한 경우, isCallApi: true
// 콜백으로 컨펌창을 띄워야하는 경우, isCallbackConfirm: true
export const validateSession = ({ authType, isCallApi = false, isCallbackConfirm = false }) => {
  const tokenStore = JSON.parse(window?.localStorage?.getItem(TOKEN_STORE_KEY) || '{}');
  // 로그인 여부
  const isLogin = tokenStore.mem_key ? true : false;
  // 관리자 여부
  const isAdmin = ['R002'].includes(tokenStore.mem_auth) ? true : false;
  // 비기너 플랜 여부
  const isBeginner = !['PLAN002', 'PLAN003'].includes(tokenStore.plan_cd) ? true : false;
  // 베이직 플랜 여부
  const isBasic = ['PLAN002', 'PLAN003'].includes(tokenStore.plan_cd) ? true : false;
  // 프로 플랜 여부
  const isPro = ['PLAN003'].includes(tokenStore.plan_cd) ? true : false;

  const handleError = (errorCode) => {
    return isCallApi ? Promise.reject(errorCode) : isCallbackConfirm ? sessionErrorConfirm(errorCode) : false;
  };

  switch (authType) {
    // 세션이 없는 경우
    case AUTH_LOGIN:
      if (!isLogin) return handleError('ERR701');
      return true;
    // 관리자가 아닌 경우
    case AUTH_ADMIN:
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin) return handleError('ERR702');
      return true;
    // 플랜구독 안한 경우
    case AUTH_BEGINNER:
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin && !isBeginner) return handleError('ERR703');
      return true;
    // 베이직 플랜 이상이 아닌 경우
    case AUTH_BASIC:
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin && !isBasic) return handleError('ERR704');
      return true;
    case AUTH_PRO:
      // 프로 플랜 이상이 아닌 경우
      if (!isLogin) return handleError('ERR701');
      if (!isAdmin && !isPro) return handleError('ERR705');
      return true;
    default:
      return true;
  }
};

// 금칙어 검사
export const validateForbiddenWord = (word) => {
  const forbiddenWordStore = JSON.parse(window?.localStorage?.getItem(FORBIDDEN_WORD_KEY) || '[]');
  const ac = new AhoCorasick(forbiddenWordStore);
  const result = ac.search(word);
  if (result.length) return result[0][1];
  return 'Y';
};

// 데이터를 MB, GB, TB로 변환하여 반환하는 함수
export const formatDataSize = (sizeInMB) => {
  if (sizeInMB < 1024) {
    // MB 단위
    return `${formatNumber(sizeInMB)}MB`;
  } else if (sizeInMB < 1024 * 1024) {
    // GB 단위
    const sizeInGB = (sizeInMB / 1024).toFixed(2);
    return `${formatNumber(sizeInGB)}GB`;
  } else {
    // TB 단위
    const sizeInTB = (sizeInMB / (1024 * 1024)).toFixed(2);
    return `${formatNumber(sizeInTB)}TB`;
  }
};

// type 별 default 이미지 경로
export function getDefaultImg(type, data) {
  const root = '/file/default/';
  let cnt = type == 'music' ? 280 : type == 'image' ? 280 : type == 'text' ? 300 : 0;
  let exp = '.webp'; // (type == "music") ? '.webp' : (type == "image")? '.webp' : '.png';
  let path = data?.thum_path;
  let ret;
  if (path) {
    ret = path;
  } else {
    let randomNum = Math.ceil(Math.random() * cnt);

    let padZero = randomNum.toString().padStart(3, '0');
    if (type == 'text') {
      ret = root + type + '/' + randomNum.toString() + exp;
    } else {
      ret = root + type + '/' + padZero + exp;
    }
    // data.thum_path = ret;
  }
  return ret;
}

export function formatDate(dateString) {
  let date;
  if (dateString) {
    date = new Date(dateString);
  } else {
    date = new Date();
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}년 ${month}월 ${day}일 가입`;
}

export function formatBadgeIcon(bdgUrl) {
  if (!bdgUrl) return null;

  const badgeUrls = bdgUrl.split('|');
  return badgeUrls.map((url, index) => <img key={index} src={url} alt={`badge-${index}`} style={{ margin: '0 5px' }} />);
}

export const makePaymentUUID = (memKey) => {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');
  const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

  const timestamp = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;

  return `${memKey}-${timestamp}`;
};

// type 별 default 이미지 경로
export function getDefaultThum(type) {
  const root = '/file/default/';
  let cnt = type == 'CATE003' ? 280 : type == 'CATE001' ? 280 : type == 'CATE004' ? 300 : 0;

  let cate_nm = type == 'CATE001' ? 'image' : type == 'CATE002' ? 'movie' : type == 'CATE003' ? 'music' : 'text';

  let exp = '.webp'; // (type == "music") ? '.webp' : (type == "image")? '.webp' : '.png';
  let ret;

  let randomNum = Math.ceil(Math.random() * cnt);

  let padZero = randomNum.toString().padStart(3, '0');
  if (type == 'CATE004') {
    ret = root + cate_nm + '/' + randomNum.toString() + exp;
  } else {
    ret = root + cate_nm + '/' + padZero + exp;
  }
  return ret;
}

// 채팅방 last 메세지 길이 조정
export function truncateMessage(message, maxLength) {
  if (message.length > maxLength) {
    return message.slice(0, maxLength) + '...';
  }
  return message;
}

// Profile 링크 클릭시
export function handleLinkClick(link, platform) {
  link ? window.open((platform !== 'X' ? 'https://www.instagram.com/' : 'https://x.com/') + link, '_blank', 'noopener noreferrer') : message.warning(`${platform} 링크가 없습니다`);
}

// Dictionary Popup
let isPopup = null;

export function openDictionary(type, el_id) {
  closeChildPopup();

  isPopup = window.open(PATH.DICTIONARY_POPUP, '_blank', 'width=800,height=900');
  isPopup.onfocus = () => {
    setTimeout(() => {
      isPopup.postMessage({ type: type, el_id: el_id }, '*');
    }, 1000);
  };
}

export function closeChildPopup() {
  if (isPopup) {
    isPopup.close();
  } else {
    console.warn('팝업이 열려 있지 않습니다.');
  }
}

// Image 파일 없을때 Error 를 Default 파일로
export const handleImageError = (e, setRef, defaultImage) => {
  e.target.onerror = null;
  if (setRef) {
    setRef.current = true;
  }
  e.target.src = defaultImage;
};

// 상단 icon 체크
const CheckIconsContext = createContext();
export const useCheckIcons = () => useContext(CheckIconsContext);
export const CheckIconsProvider = ({ children }) => {
  const [checkMarket, setCheckMarket] = useState(false);
  const [checkMsg, setCheckMsg] = useState(false);
  const [checkAlert, setCheckAlert] = useState(false);

  const iconCheck = useRef(false);

  const iconCount = async (memKey) => {
    try {
      if (!memKey) return;
      const params = { mem_key: memKey };
      const response = await api.iconCheck({ params });
      const data = response.data[0];
      if (data) {
        setCheckMarket(data.market_cnt > 0);
        setCheckMsg(data.chat_cnt > 0);
        setCheckAlert(data.alert_cnt > 0);
      }
    } catch (error) {
      console.error('icon 최신 조회 에러:', error);
    }
  };

  const [checkList, setCheckList] = useState(false);
  return (
    <CheckIconsContext.Provider value={{ checkMarket, setCheckMarket, checkMsg, setCheckMsg, checkAlert, setCheckAlert, iconCheck, iconCount, checkList, setCheckList }}>
      {children}
    </CheckIconsContext.Provider>
  );
};

export function formatEncodingBoardDate(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatDateYYYYMMDD(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function getYouTubeThumbnail(url) {
  const regex = /(?:youtube\.com\/.*v=|youtu\.be\/)([^&]+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    // 0.jpg : 기본 해상도, hqdefault.jpg 고화질, maxresdefault.jpg 고화질
    return `https://img.youtube.com/vi/${match[1]}/maxresdefault.jpg`;
  }
  return null;
}

