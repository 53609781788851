import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useWidth from '@hooks/useWidth';
import { Button, Checkbox, Input, List, message, Modal, Row, Select, Space } from 'antd';
import { MoreOutlined, StarFilled, StarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import '@assets/css/admin.css';

import thumbanil from '@assets/images/common/thumb.png';
import tashIcon from '@assets/images/common/i-trashLineB.svg';
import activeTrashIcon from '@assets/images/common/i-trashLineB.svg';
import starIcon from '@assets/images/common/star_icon.png';
import starGIcon from '@assets/images/common/i-starOff.svg';
import searchIcon from '@assets/images/common/search_icon.png';
import listIcon from '@assets/images/common/list_icon.png';
import folderIcon from '@assets/images/common/folder_icon.png';
import { isEmpty, useDebounce } from '@utils/lib';
import * as api from '@api/index';
import dayjs from 'dayjs';
import { MultiBackend } from 'react-dnd-multi-backend';
import HTML5toTouch from '@assets/js/HTML5toTouch';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { MARKET_SALES_HOME, PROMPT_DETAIL, PROMPT_MANAGEMENT, SALES_PROMPT_HOME } from '@routes/pathName';
import ContextMenu from '@components/ContextMenu'; // 설정 파일 경로
import Banner from '@components/slider/Banner';

const PromptManagement = () => {
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);
  const width = useWidth();
  const [listTab, setListTab] = useState(0);
  const cateList = useRef(); // 카테고리 리스트 ( 공통코드)
  const aiModelList = useRef(); // AI모델 리스트 ( 공통코드)

  const sortOptions = [
    { label: '최신순', value: 'recent' },
    { label: '제목순', value: 'title' },
  ];
  const initUserPromptList = {
    query: {
      search_cate: '',
      search_model: '',
      keyword_text: '',
      sort: 'recent',
      bookmark: '',
      mem_key: auth?.mem_key,
      parent_obj_id: auth?.mem_key + '-1', // 최상위 폴더
      trash_obj_id: auth?.mem_key + '-2', // 휴지통
      offset: 0,
      limit: 20,
    },
    items: [],
    currentTitle: auth?.mem_nick + '님의 폴더',
  };
  const [userPromptList, setUserPromptList] = useState({ ...initUserPromptList, ...state?.userPromptList });
  const [selectedItems, setSelectedItems] = useState([]); //선택된 item
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 }); // 우클릭 position
  const [menuList, setMenuList] = useState([]); // 우클릭 메뉴 List
  const [editFolderModal, setEditFolderModal] = useState({ visible: false, title: '', editItem: null }); // 폴더명저장 모달
  const [editable, setEditable] = useState(false); // 폴더명저장 버튼 컨트롤

  const [isSearch, setIsSearch] = useState(false);

  /** 핸들러 */
  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 200),
    [],
  );

  // 섬네일
  const getThumbnail = (item) => {
    switch (item.folder_div) {
      case '00': // 폴더
        return <img src={folderIcon} className="icon" />;
      case '01': // 프롬프트
        return <img src={item.thum_path} />;
      case '99': // 휴지통
        return <img src={tashIcon} className="icon" />;
    }
  };

  /** drag and drop */
  const handleSort = (value) => {
    const newQuery = { ...userPromptList.query, sort: value };
    setUserPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getUserPromptList(newQuery)); // 프롬프트 리스트 조회
  };

  const handleBookmarks = () => {
    const newQuery = { ...userPromptList.query, bookmark: isEmpty(userPromptList.query.bookmark) ? 'Y' : '' };
    setUserPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getUserPromptList(newQuery)); // 프롬프트 리스트 조회
  };

  // 검색조건 변경
  const handleSearchChange = (name, value) => {
    const newQuery = { ...userPromptList.query, [name]: value };
    setUserPromptList((prev) => ({ ...prev, query: newQuery }));
  };

  // 검색 버튼 클릭 시
  const handleSubmit = () => {
    if (userPromptList.query.parent_obj_id == userPromptList.query.trash_obj_id) {
      // 휴지통 내부일 때
      const newQuery = { ...userPromptList.query, parent_obj_id: userPromptList.query.trash_obj_id };
      setUserPromptList((prev) => ({ ...prev, query: newQuery }));
      navigate(PROMPT_MANAGEMENT, { state: { userPromptList: { ...userPromptList, query: newQuery } } });
    } else {
      if (userPromptList.query.keyword_text) {
        setIsSearch(true);
      } else {
        setIsSearch(false);
      }
      const newQuery = { ...userPromptList.query, parent_obj_id: auth?.mem_key + '-1' };
      setUserPromptList((prev) => ({ ...prev, query: newQuery }));
      navigate(PROMPT_MANAGEMENT, { state: { userPromptList: { ...userPromptList, query: newQuery } } });
    }
  };

  // 체크
  const handleChecked = (e, targetId) => {
    const isChecked = e.target.checked; // 현재 체크 상태
    setSelectedItems((prev) => {
      if (isChecked) {
        // 체크된 경우 targetId 추가
        return [...prev, targetId];
      } else {
        // 체크 해제된 경우 targetId 제거
        return prev.filter((id) => id !== targetId);
      }
    });
  };

  // 드롭 시
  const handleDrop = (draggedItems, targetItem) => {
    draggedItems.forEach((draggedId) => {
      // draggedId를 이용해 아이템을 targetId에 드롭 처리
      console.log(`드래그한 항목: ${draggedId}, 드롭 대상: ${targetItem.obj_id}`);
    });

    // 드래그 대상이 경로에 포함되어 있으면 return 처리
    if (draggedItems.some((dragItem) => targetItem.path.includes(dragItem))) {
      return;
    }

    movePromptFolder(targetItem.obj_id, draggedItems);
  };

  // 폴더 이동 (휴지통으로이동 포함)
  const movePromptFolder = async (parentId, draggedItems) => {
    const msg = auth.mem_key + '-2' === parentId ? '삭제' : '이동';
    if (draggedItems.length === 0) {
      message.error(msg + '할 프롬프트를 선택해주세요.');
      return false;
    }
    try {
      if (await window.confirm(msg + '하시겠습니까?')) {
        setLoading((prev) => ({ ...prev, movePromptFolder: true }));
        const { data } = await api.movePromptFolder({
          mem_key: userPromptList.query.mem_key,
          parent_obj_id: parentId,
          drag_items: draggedItems,
        });
        if (data.returnStatus === 'success') {
          message.success(msg + '되었습니다');
          getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
        } else {
          message.error(msg + '중 에러가 발생했습니다.');
          return false;
        }
      }
    } catch (e) {
      console.log(msg + '에러');
    } finally {
      setSelectedItems([]); // selected Item 초기화
      setLoading((prev) => ({ ...prev, movePromptFolder: false }));
    }
  };

  // 북마크 저장, 해제
  const toggleBookmark = async (targetItem) => {
    try {
      const { data } = await api.toggleBookmark({
        mem_key: userPromptList.query.mem_key,
        obj_id: targetItem.obj_id,
        bookmark: isEmpty(targetItem.bookmark) ? 'Y' : '',
      });

      message.info(isEmpty(targetItem.bookmark) ? '즐겨찾기 등록' : '즐겨찾기 해제');

      getUserPromptList(userPromptList.query);
    } catch (e) {}
  };

  // 더블클릭시 해당 폴더로 진입
  const isNavigating = useRef(false);

  const handleDoubleClick = (item) => {
    setIsSearch(false);
    /** 00:폴더 / 01:파일 / 99:휴지통 */
    if (item.folder_div != '01' && item.parent_obj_id != userPromptList.query.trash_obj_id) {
      // 파일이 아니고, 휴지통에 들어있지 않을 때 진입가능
      const newQuery = {
        ...userPromptList.query,
        parent_obj_id: item.obj_id,
        // 폴더 진입시 검색조건 초기화
        search_cate: '',
        search_model: '',
        keyword_text: '',
      };
      setUserPromptList((prev) => ({ ...prev, query: newQuery, currentTitle: item.obj_nm }));
      navigate(PROMPT_MANAGEMENT, { state: { userPromptList: { ...userPromptList, query: newQuery, currentTitle: item.obj_nm } } });
    } else if (item.folder_div == '01') {
      if (item.prpt_stat == '00' || item.prpt_stat == '01') {
        // 임시저장, 작성완료일 경우 등록 페이지로 이동
        navigate(SALES_PROMPT_HOME, {
          state: { prpt_id: item.obj_id },
        });
        return;
      } else {
        // 파일일 경우 상세로 진입
        navigate(PROMPT_DETAIL, {
          state: { prpt_id: item.obj_id },
        });
        updatePromptView(item.obj_id, 'V'); // 조회 카운트 update
      }
    }
  };

  // 조회수 업데이트
  const updatePromptView = (promptId, viewType) => {
    //  조회 타입 'D'/'P'/'V' (다운로드/플레이/조회)
    api.updatePromptView({ prpt_id: promptId, view_type: viewType });
  };

  const handleRightClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    // 휴지통이거나 부모 폴더 일 경우 return
    if (item.folder_div == '99' || item.parent_folder == 'Y') return;

    const selectedItem = [item.obj_id];
    setSelectedItems(selectedItem);

    if (item.parent_obj_id == userPromptList.query.trash_obj_id) {
      // 부모가 휴지통일때
      setMenuList([
        {
          id: 'restore',
          name: '복원',
          callback: () => {
            restorePromptFolder(selectedItem);
          },
        },
        {
          id: 'remove',
          name: '영구삭제',
          callback: () => {
            removePromptFolder(selectedItem);
          },
        },
      ]);
    } else if (item.folder_div == '01') {
      // 부모가 휴지통이 아니고, 프롬프트일 때
      setMenuList([
        {
          id: 'add',
          name: '삭제',
          callback: () => {
            movePromptFolder(userPromptList.query.trash_obj_id, selectedItem);
          },
        },
      ]);
    } else {
      setMenuList([
        {
          id: 'edit',
          name: '이름 바꾸기',
          callback: () => {
            editPromptFolder(item.obj_id);
          },
        },
        {
          id: 'add',
          name: '삭제',
          callback: () => {
            movePromptFolder(userPromptList.query.trash_obj_id, selectedItem);
          },
        },
      ]);
    }

    setMenuPosition({ x: e.pageX - 105, y: e.pageY }); // 메뉴 위치 설정
  };

  // 유저 프롬프트 목록 조회
  const getUserPromptList = useCallback(async (query) => {
    try {
      setLoading((prev) => ({ ...prev, promptLoad: true }));
      const { data } = await api.getUserPromptList(query);

      setUserPromptList((prev) => ({
        ...prev,
        items: data.items,
        currentTitle: prev.currentTitle,
      }));
    } catch (e) {
      console.log('조회 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, promptLoad: false }));
    }
  }, []);

  // 휴지통에서 복원
  const restorePromptFolder = async (selectedItem) => {
    if (selectedItem.length === 0) {
      message.error('복원할 프롬프트를 선택해주세요.');
      return false;
    }
    if (!(await window.confirm('복원 하시겠습니까?'))) {
      return false;
    }

    try {
      setLoading((prev) => ({ ...prev, restorePromptFolder: true }));
      const { data } = await api.restorePromptFolder({
        mem_key: userPromptList.query.mem_key,
        drag_items: selectedItem || selectedItems, // 복원할 item들
      });
      if (data.returnStatus == 'success') getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prev) => ({ ...prev, restorePromptFolder: false }));
    }
  };

  // 휴지통에서 영구삭제
  const removePromptFolder = async (selectedItem) => {
    if (selectedItem.length === 0) {
      message.error('영구삭제할 프롬프트를 선택해주세요.');
      return false;
    }
    if (!(await window.confirm('영구삭제 하시겠습니까?', 'delete'))) return false;

    try {
      setLoading((prev) => ({ ...prev, restorePromptFolder: true }));
      const { data } = await api.removePromptFolder({
        mem_key: userPromptList.query.mem_key,
        drag_items: selectedItem || selectedItems, // 삭제할 item들
      });
      if (data.returnStatus == 'success') {
        message.success('삭제되었습니다.');
        getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
      } else {
        message.error('삭제중 에러가 발생했습니다.');
        return false;
      }
    } catch (e) {
      message.error('삭제중 에러가 발생했습니다.');
    } finally {
      setLoading((prev) => ({ ...prev, restorePromptFolder: false }));
    }
  };

  // 폴더명 변경 모달 open
  const editPromptFolder = (targetId) => {
    const title = targetId ? '이름 변경' : '새폴더 추가';
    const editItem = targetId
      ? userPromptList.items.find((item) => item.obj_id === targetId)
      : {
          mem_key: auth?.mem_key,
          parent_obj_id: !isSearch ? userPromptList.query.parent_obj_id : '',
          folder_div: '00', //폴더
          folder_nm: '',
        };
    setEditFolderModal({ visible: true, title: title, editItem: editItem }); //폴더명 변경 모달창 open
  };

  // 폴더명 변경 input change 핸들러
  const handleInputChange = (e) => {
    setEditable(e.target.value ? false : true);
    if (e.target.value) {
      setEditFolderModal((prev) => ({
        ...prev,
        editItem: { ...prev.editItem, folder_nm: e.target.value },
      }));
    }
  };

  // 모달창 close
  const closeModal = (flag) => {
    switch (flag) {
      case 'folderName': // 폴더 이름변경
        setEditFolderModal({ visible: false });
        setEditable(false);
    }
  };

  // 폴더명 저장
  const debounceEditFolderName = async () => {
    handleDebounce(() => editFolderName());
  };
  const editFolderName = async (e) => {
    if (isSearch) {
      editFolderModal.editItem.parent_obj_id = auth?.mem_key + '-1';
    }
    try {
      if (await window.confirm('저장하시겠습니까?')) {
        const { data } = await api.savePromptFolder(editFolderModal.editItem);
        if (data.returnStatus == 'success') {
          message.success('저장되었습니다.');
          getUserPromptList(userPromptList.query); // 프롬프트 리스트 조회
        } else {
          message.error('저장중 에러가 발생했습니다.');
          return false;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setEditFolderModal({ visible: false, title: '', editItem: null });
      setEditable(false);
    }
  };

  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));
      // 카테고리
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      cateList.current = response1.data;
      // AI모델
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response2.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };

  useEffect(() => {
    // 첫 진입시
    if (isEmpty(state)) {
      getCommonCode(); // 공통코드 조회
      setUserPromptList(initUserPromptList);
      handleDebounce(() => getUserPromptList(initUserPromptList.query)); // 프롬프트 리스트 조회
    } else {
      setUserPromptList((prev) => ({ ...prev, query: state.userPromptList.query }));
      handleDebounce(() => getUserPromptList(state.userPromptList.query)); // 프롬프트 리스트 조회
    }
  }, [state]);

  useEffect(() => {
    setSelectedItems([]); // selected Item 초기화
  }, [userPromptList]);

  // 드래그앤 드랍
  const draggableDroppable = (item, selectedItems, setSelectedItems, userPromptList, handleDrop) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'ITEM',
      item: () => {
        // 드래그 item 설정 & 드래그 시작 이벤트 설정

        // selectedItems에 포함되지 않은 항목 드래그 시 선택항목에 추가
        if (!selectedItems.includes(item.obj_id)) {
          requestAnimationFrame(() => {
            setSelectedItems([item.obj_id]);
          });
          return { selectedItems: [item.obj_id] };
        }
        return { selectedItems };
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: item.folder_div != '99' && item.parent_obj_id != userPromptList.query.trash_obj_id && item.parent_folder != 'Y', // 휴지통이거나 휴지통 내부이거나, 상위폴더인 경우 드래그 불가
      dragSourceOptions: {
        touch: { delay: 200 }, // 모바일 터치 드래그 딜레이
      },
    }));

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
      accept: 'ITEM', // type이 ITEM인 객체만 drop 가능
      drop: (draggedItem) => {
        handleDrop(draggedItem.selectedItems, item);
      }, // 드래그된 모든 아이템과 현재 ID를 전달
      canDrop: (draggedItem) => {
        // 드래그된 아이템의 obj_id가 현재 아이템과 같거나 folder_div가 '01'이 아니면 드롭 가능
        return !selectedItems.includes(item.obj_id) && item.folder_div != '01';
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }));

    const dndRef = React.useRef(null);
    drag(drop(dndRef)); // drag와 drop을 결합

    return { isDragging, isOver, canDrop, dndRef };
  };

  const ListComponent = () => {
    return (
      <div className="flexColCenter">
        <div className="list flexColCenter">
          {userPromptList.items.map((item) => {
            const { isDragging, isOver, canDrop, dndRef } = draggableDroppable(item, selectedItems, setSelectedItems, userPromptList, handleDrop);

            return (
              <div
                ref={dndRef}
                key={item.obj_id}
                data-id={item.obj_id}
                className={`flexRowBetween ${selectedItems.includes(item.obj_id) ? 'selected' : ''} ${isOver && canDrop ? 'dropHover' : ''} `}
                onDoubleClick={() => {
                  handleDoubleClick(item);
                }}
                onContextMenu={() => handleRightClick(event, item)}
                onTouchEnd={(e) => {
                  if (e.target.closest('.star') || e.target.closest('.more')) return;
                  handleDoubleClick(item);
                }} // 모바일 단일 터치
              >
                {item.folder_div != '99' && item.parent_folder != 'Y' ? (
                  <Checkbox className="checkbox" checked={selectedItems.includes(item.obj_id)} onChange={(e) => handleChecked(e, item.obj_id)} />
                ) : (
                  <div className="checkbox"></div>
                )}

                <div className="thumbnail flexColCenter">{getThumbnail(item)}</div>
                {item.parent_folder != 'Y' ? (
                  <>
                    <div className="title flexColStart">
                      <h1>{item.obj_nm}</h1>
                      {item.folder_div == '01' ? (
                        <div className="stateInfoBox">
                          {/* <img src={item.icon_path} /> */}
                          <h2 className="majorModelW">
                            <span>{item.ai_model_nm}</span>
                          </h2>
                          <p>
                            {item.cate_nm}
                            {!isEmpty(item.genre_nm) ? ` / ${item.genre_nm}` : '대체 텍스트'}
                          </p>
                        </div>
                      ) : (
                        <div className="flexRowStart">
                          <p>총 {item.child_cnt}개</p>
                        </div>
                      )}
                    </div>

                    <div className="prptStatCol">
                      <span
                        className={`
                            approval
                            ${
                              item?.prpt_stat === '00'
                                ? 'done'
                                : item?.prpt_stat === '01'
                                  ? 'disable'
                                  : item?.prpt_stat === '10'
                                    ? ' request'
                                    : item?.prpt_stat === '20'
                                      ? 'ok'
                                      : item?.prpt_stat === '30'
                                        ? 'on'
                                        : item?.prpt_stat === '98'
                                          ? 'rejection'
                                          : item?.prpt_stat === '99'
                                            ? ' off'
                                            : ''
                            }
                          `}
                      >
                        {item?.prpt_stat === '00'
                          ? '작성완료'
                          : item?.prpt_stat === '01'
                            ? '임시저장'
                            : item?.prpt_stat === '10'
                              ? '판매요청'
                              : item?.prpt_stat === '20'
                                ? '승인'
                                : item?.prpt_stat === '30'
                                  ? '판매중'
                                  : item?.prpt_stat === '98'
                                    ? '판매거절'
                                    : item?.prpt_stat === '99'
                                      ? ' 판매중지'
                                      : ''}
                      </span>
                    </div>

                    <div className="channel">
                      <p>{item.path_nm.slice(0, -1).join(' > ')}</p>
                    </div>

                    <div className="star flexColCenter" onClick={() => toggleBookmark(item)}>
                      {item.bookmark ? (
                        <>
                          <img src={starIcon} />
                        </>
                      ) : (
                        <>
                          <StarFilled style={{ color: '#ccc' }} />
                          <img src={starIcon} className="activeImg" />
                        </>
                      )}
                    </div>

                    <div className="flexColCenter date">{dayjs(item.lst_dt).format('YYYY-MM-DD')}</div>

                    <div className="more flexColCenter">
                      <button className="i-more" onClick={() => handleRightClick(event, item)} />
                    </div>
                  </>
                ) : (
                  <div className="title flexColStart">
                    <h1>{item.obj_nm}</h1>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const CardComponent = () => {
    return (
      <div className="flexColCenter">
        <div className="list2 flexRowStart">
          {userPromptList.items.map((item) => {
            const { isDragging, isOver, canDrop, dndRef } = draggableDroppable(item, selectedItems, setSelectedItems, userPromptList, handleDrop);

            return (
              <div
                id="img"
                ref={dndRef}
                key={item.obj_id}
                data-id={item.obj_id}
                className={`
                  flexColCenter ${selectedItems.includes(item.obj_id) ? 'selected' : ''} ${isOver && canDrop ? 'dropHover' : ''} ${item.folder_div == '01' ? '' : 'folder'}  ${item.folder_div == '99' ? 'trash' : ''}`}
                onDoubleClick={() => {
                  handleDoubleClick(item);
                }}
                onTouchEnd={(e) => {
                  if (e.target.closest('.star') || e.target.closest('.more')) return;
                  handleDoubleClick(item);
                }} // 모바일 단일 터치
              >
                <div className={`thumbnail flexColCenter `}>
                  <div className="absoluteBox flexColBetween">
                    <div className="flexRowStart user">
                      {item.folder_div != '99' && item.parent_folder != 'Y' ? (
                        <Checkbox
                          className="checkbox"
                          checked={selectedItems.includes(item.obj_id)}
                          onChange={(e) => handleChecked(e, item.obj_id)}
                          disabled={item.folder_div === '99' ? true : false}
                        />
                      ) : (
                        <div className="checkbox"></div>
                      )}

                      {/* <img src={item.icon_path} /> */}
                      <p>{item.ai_model_nm}</p>
                    </div>

                    <h1>{item.obj_nm}</h1>
                  </div>

                  <h1>{item.obj_nm}</h1>
                  {item.folder_div == '01' ? <img src={item.thum_path} /> : ''}
                </div>
                {item.parent_folder != 'Y' ? (
                  <div className="bottom flexRowBetween">
                    {item.folder_div == '01' ? (
                      <p>
                        {item.cate_nm}
                        {!isEmpty(item.genre_nm) ? `/${item.genre_nm}` : '대체 텍스트'}
                      </p>
                    ) : (
                      <p>총 {item.child_cnt}개</p>
                    )}
                    <div className="flexRowCenter">
                      <div className="star" onClick={() => toggleBookmark(item)}>
                        {item.bookmark ? <img src={starIcon} /> : <StarFilled style={{ color: '#ccc' }} />}
                      </div>

                      <div className="more flexColCenter">
                        <button class="i-more" onClick={() => handleRightClick(event, item)} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bottom flexRowBetween"></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <article className="flexColCenter thumSearch" id="admin">
        <div className="flexColCenter">
          <ContextMenu menuPosition={menuPosition} menuList={menuList} />
          {editFolderModal.visible && (
            <Modal
              open={editFolderModal.visible}
              title={<div>{editFolderModal.title}</div>}
              okText="저장"
              cancelText="취소"
              closable={false}
              maskClosable={false}
              okButtonProps={{ style: { order: 0 } }}
              cancelButtonProps={{ style: { order: 1 } }}
              onOk={debounceEditFolderName}
              onCancel={() => closeModal('folderName')}
            >
              <div>제목을 입력하세요.</div>

              <Input placeholder="제목을 입력해주세요." id="newName" allowClear defaultValue={editFolderModal.editItem?.folder_nm} onChange={handleInputChange} />
            </Modal>
          )}
          <div className="searchBox flexRowBetween">
            <div className="flexRowCenter leftBox">
              {width < 700 ? (
                <>
                  <Select options={sortOptions} className="select" value={userPromptList.query.sort} onChange={handleSort} onTouchStart={(e) => e.stopPropagation()} />
                  {userPromptList.query.parent_obj_id != userPromptList.query.trash_obj_id ? (
                    <>
                      <img src={starIcon} onClick={handleBookmarks} />
                      <img src={tashIcon} onClick={() => movePromptFolder(userPromptList.query.trash_obj_id, selectedItems)} />
                    </>
                  ) : (
                    <>
                      {/*복원*/}
                      <img src={tashIcon} onClick={() => restorePromptFolder(selectedItems)} />
                      {/*영구삭제*/}
                      <img src={tashIcon} onClick={() => removePromptFolder()} />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Select
                    className="select"
                    allowClear
                    options={cateList.current}
                    placeholder="카테고리"
                    fieldNames={{ label: 'cd_nm', value: 'cd' }}
                    value={userPromptList.query.search_cate || null}
                    onChange={(e) => handleSearchChange('search_cate', e)}
                  />
                  <Select
                    className="select"
                    allowClear
                    options={aiModelList.current}
                    placeholder="AI 모델"
                    fieldNames={{ label: 'cd_nm', value: 'cd' }}
                    value={userPromptList.query.search_model || null}
                    onChange={(e) => handleSearchChange('search_model', e)}
                  />

                  <div className="search flexRowStart">
                    <img src={searchIcon} onClick={handleSubmit} />
                    <Input
                      className="input"
                      placeholder="검색어를 입력해주세요."
                      value={userPromptList.query.keyword_text}
                      onChange={(e) => handleSearchChange('keyword_text', e.target.value)}
                      onPressEnter={handleSubmit}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="flexRowCenter rightBox">
              {width < 700 ? (
                <img src={searchIcon} onClick={handleSubmit} />
              ) : (
                <>
                  <button id="logoutBtn" onClick={handleBookmarks}>
                    <div>
                      <img src={starIcon} />
                      <img src={starIcon} className="activeImg" />
                    </div>
                  </button>

                  {userPromptList.query.parent_obj_id != userPromptList.query.trash_obj_id ? (
                    <>
                      <button id="logoutBtn" onClick={() => editPromptFolder()}>
                        <p className="text">새폴더</p>
                      </button>
                      <button id="logoutBtn" onClick={() => movePromptFolder(userPromptList.query.trash_obj_id, selectedItems)}>
                        <div>
                          <img src={tashIcon} />
                          <img src={activeTrashIcon} className="activeImg" />
                        </div>
                        <p className="text">삭제</p>
                      </button>
                    </>
                  ) : (
                    <>
                      <button id="logoutBtn" onClick={() => restorePromptFolder(selectedItems)}>
                        <div>
                          <img src={tashIcon} />
                          <img src={activeTrashIcon} className="activeImg" />
                        </div>
                        <p className="text">복원</p>
                      </button>
                      <button id="logoutBtn" onClick={() => removePromptFolder(selectedItems)}>
                        <div>
                          <img src={tashIcon} />
                          <img src={activeTrashIcon} className="activeImg" />
                        </div>
                        <p className="text">영구삭제</p>
                      </button>
                    </>
                  )}

                  <Select options={sortOptions} className="select" value={userPromptList.query.sort} onChange={handleSort} />
                </>
              )}

              <div className="flexRowCenter listBtn">
                <div className="btn flexColCenter" onClick={() => setListTab(0)} id={listTab === 0 ? 'active' : 'nothing'}>
                  <p>
                    <UnorderedListOutlined />
                  </p>
                </div>
                <div className="btn flexColCenter" onClick={() => setListTab(1)} id={listTab === 1 ? 'active' : 'nothing'}>
                  <img src={listIcon} />
                </div>
              </div>
            </div>

            <div className="mobile flexRowBetween">
              <Select
                className="select"
                placeholder="카테고리"
                options={cateList.current}
                fieldNames={{ label: 'cd_nm', value: 'cd' }}
                value={userPromptList.query.search_cate}
                onChange={(e) => handleSearchChange('search_cate', e)}
                onTouchStart={(e) => e.stopPropagation()}
              />
              <Select
                className="select"
                allowClear
                options={aiModelList.current}
                placeholder="AI 모델"
                fieldNames={{ label: 'cd_nm', value: 'cd' }}
                value={userPromptList.query.search_model || null}
                onChange={(e) => handleSearchChange('search_model', e)}
                onTouchStart={(e) => e.stopPropagation()}
              />

              <div className="search flexRowStart">
                <Input className="input" placeholder="검색어를 입력해주세요." value={userPromptList.query.keyword_text} onChange={(e) => handleSearchChange('keyword_text', e.target.value)} />
              </div>
            </div>
          </div>

          {listTab === 0 && <ListComponent />}

          {listTab === 1 && <CardComponent />}
        </div>
      </article>
    </DndProvider>
  );
};

export default PromptManagement;
