import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Flex, Form, Input, InputNumber, message, Radio, Select, Tooltip, Upload, Modal, Image, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as api from '@api/index';
import { DeleteOutlined, InboxOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { PROFILE_PAGE, PROMPT_MANAGEMENT, MARKET_SALES_HOME, PROMPT_DETAIL } from '@routes/pathName';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import '@assets/css/form.css';
import useWidth from '@hooks/useWidth';
import closeIcon from '@assets/images/common/close_icon.png';
import profileIcon from '@assets/images/profile/profile_icon.png';
import promptIcon from '@assets/images/common/i-builder.svg';
import aiIcon from '@assets/images/common/i-ai.svg';
import tagIcon from '@assets/images/common/i-tag.svg';
import resultIcon from '@assets/images/common/i-aiResult.svg';
import AiModelCertModal from '@components/AiModelCertModal';
import SellerRegist from '@components/SellerRegist';
import { getDefaultImg, getDefaultThum, getLocalStorage } from '@utils/lib';
import SalesTerms from '@components/SalesTerms';
import { IMAGES } from '@utils/image';

const {Text} = Typography;

// 프롬프트 활용 가이드
const remarkPlaceholder =
  '프롬프트를 다양하게 활용할 수 있도록 팁을 제공해주세요.\n\n' +
  '1. 변수 위치 표시\n' +
  '   예시:  [동물] is running on the street in the [시간].\n\n' +
  '2. 변수에 사용할 수 있는 추천 단어 리스트\n' +
  '   예시: [동물] : Cat, Dog, Lion [시간] : Morning, Afternoon, Evening\n\n' +
  '3. 프롬프트 작성 또는 활용 아이디어\n' +
  '   예시: 이 프롬프트는 16:9 비율로 만드는 것이 좋습니다.';

const uploadThumbnailExtension = process.env.REACT_APP_UPLOAD_THUMBNAIL;
const uploadImageExtension = process.env.REACT_APP_UPLOAD_IMAGE;
const uploadMusicExtension = process.env.REACT_APP_UPLOAD_MUSIC;
const uploadVideoExtension = process.env.REACT_APP_UPLOAD_VIDEO;
const uploadTextExtension = process.env.REACT_APP_UPLOAD_TEXT;
const uploadSize = process.env.REACT_APP_UPLOAD_SIZE;
const uploadTotSize = process.env.REACT_APP_UPLOAD_TOT_SIZE;

const SalesPrompt = (props) => {
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';
  const totalDataSize = getLocalStorage('prptbk-token', 'total_data_size') || '';
  const navigate = useNavigate();
  const width = useWidth();

  const { state } = useLocation();
  const prpt_id = state?.prpt_id || null;

  const promptInitValue = {
    cate_cd: state?.cate_cd || 'CATE003', // 음악
    sale_div: 'FREE', // 무료
    sale_amt: 0, // 0원
  };

  const [selectedCategory, setSelectedCategory] = useState(promptInitValue.cate_cd); // 선택 카테고리 (초기값 :음악)
  const [selectedAiModel, setSelectedAiModel] = useState(null); // 선택 모델
  const [selectedGenre, setSelectedGenre] = useState(null); // 선택 장르
  const [selectedSubGenre, setSelectedSubGenre] = useState(null); // 선택 서브 장르
  const [currentPrptStat, setCurrentPrptStat] = useState('01'); // 현재 프롬프트 상태
  const [isOnSale, setIsOnSale] = useState(false); // 현재 프롬프트 상태가 판매중/판매중지 여부

  const [certYn, setCertYn] = useState(false); // AI모델 인증필요 여부
  const [isCerted, setIsCerted] = useState(false); // AI모델 인증완료 여부
  const [selectedPay, setSelectedPay] = useState(promptInitValue.sale_div); // 유무료 구분
  const [isBusinessAccount, setIsBusinessAccount] = useState(false); // 사업자등록 여부

  const [tags, setTags] = useState([]);

  const [inputValue, setInputValue] = useState('');
  const [aiInfoFlag, setAiInfoFlag] = useState(false); // AI 모델 정보 추가 flag
  const [isAiModalVisible, setAiIsModalVisible] = useState(false); // AI 모델 인증 모달 show
  const [isSellerModalVisible, setIsSellerModalVisible] = useState(false); // 판매자인증 모달 show

  const [fileGrpSeq, setFileGrpSeq] = useState(null); // 파일 그룹 번호
  const [thumFile, setThumFile] = useState([]); // 대표이미지 파일
  const [attachFile, setAttachFile] = useState([]); // 첨부파일
  const [delFileCaches, setDelFileCaches] = useState([]); // 삭제 첨부 파일

  const [saveType, setSaveType] = useState('01');
  const [form] = Form.useForm();

  const [salesTermsVisible, setSalesTermsVisible] = useState(false); // 이용약관 모달

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  // 공통코드
  const [categoryList, setCategoryList] = useState([]); // 카테고리코드
  const [allAiModelList, setAllAiModelList] = useState([]); // 전체 AI모델코드
  const [allGenreList, setAllGenreList] = useState([]); // 전체 장르코드
  const [allSubGenreList, setAllSubGenreList] = useState([]); // 전체 서브장르코드
  const [saleDivList, setSaleDivList] = useState([]); // 판매구분

  const [filterAiModelList, setFilterAiModelList] = useState([]); // 필터링된 AI모델코드
  const [filterGenreList, setFilterGenreList] = useState([]); // 필터링된 장르코드
  const [filterSubGenreList, setFilterSubGenreList] = useState([]); // 필터링된 서브장르코드

  // 디폴트 설정 완료 여부
  const [isDoneConfig, setIsDoneConfig] = useState(true);

  // 초기화
  const handleInit = () => {
    if (state) {
      // 수정일 경우 뒤로가기
      window.history.back();
      return;
    }

    form.resetFields(); // form 초기화

    setSelectedCategory(promptInitValue.cate_cd);
    setSelectedPay(promptInitValue.sale_div);

    setAiInfoFlag(false);

    setCertYn(false);
    setIsCerted(false);

    setThumFile([]);
    setAttachFile([]);
    setDelFileCaches([]);
    setFilterSubGenreList([]);
  };

  // 조회
  const getSalesPrompt = useCallback(
    async (query) => {
      try {
        const { data } = await api.getSalesPrompt(query);

        // 카테고리
        if (data?.item?.cate_cd) {
          setSelectedCategory(data?.item?.cate_cd);
        }
        // ai 모델
        if (data?.item?.ai_model_cd) {
          const defaultFilterAiModelList = allAiModelList?.filter((item) => item?.cate_cd === data?.item?.cate_cd);
          setFilterAiModelList(defaultFilterAiModelList);
          setSelectedAiModel(data?.item?.ai_model_cd);

          // 인증여부 설정
          const selectedAiModelList = defaultFilterAiModelList?.filter((item) => item?.ai_model_cd === data?.item?.ai_model_cd);
          const defaultCertyn = selectedAiModelList[0]?.cert_yn === 'Y';
          setCertYn(defaultCertyn);

          // 인증여부가 Y이면 유저 인증 체크
          if (defaultCertyn) aiModelCertChk(data?.item?.ai_model_cd);
        }
        // 장르코드
        if (data?.item?.genre_cd) {
          const defaultFilterGenreList = allGenreList?.filter((item) => item?.parent_cd === data?.item?.cate_cd);
          setFilterGenreList(defaultFilterGenreList);
          setSelectedGenre(data?.item?.genre_cd);
        }
        // 서브장르코드
        if (data?.item?.sub_genre_cd) {
          const defaultFilterSubGenreList = allSubGenreList?.filter((item) => item?.parent_cd === data?.item?.genre_cd);
          setFilterSubGenreList(defaultFilterSubGenreList);
          setSelectedSubGenre(data?.item?.sub_genre_cd);
        }
        // 프롬프트 상태
        if (data?.item?.prpt_stat) {
          setCurrentPrptStat(data?.item?.prpt_stat);
          if (['30', '99'].includes(data?.item?.prpt_stat)) setIsOnSale(true);
        }
        // form 정보
        form.setFieldsValue({ ...data?.item, sale_agree: data?.item?.sale_agree === 'Y' ? true : false });

        // 태그
        if (data?.item?.tag) {
          setTags(data?.item?.tag.split(','));
        }

        // 대표이미지
        if (data?.item?.thum_path) {
          const blob = await (await fetch(data?.item?.thum_path)).blob();
          const file = new File([blob], 'thumbnail', { type: blob.type });
          file.url = data?.item?.thum_path;
          file.status = 'done';
          setThumFile([file]);
        }

        // 첨부파일
        if (data?.fileList.length > 0) {
          const files = [];

          for (const file of data?.fileList) {
            const blob = await (await fetch(file.file_dwn_path)).blob();
            const newFile = new File([blob], file.file_org_nm, { type: blob.type });

            newFile.uid = file.file_seq || file.uid || `${file.file_nm}-${file.file_seq}`;
            newFile.status = 'done';
            newFile.url = file.file_dwn_path;

            files.push(newFile);
          }
          setAttachFile(files);
        }

        // 첨부파일
        setFileGrpSeq(data?.item?.file_grp_seq); // 파일 그룹 번호

        // 디폴트 설정 완료
        setIsDoneConfig(false);
      } catch (error) {
        console.error('조회 error', error);
      }
    },
    [allAiModelList, allGenreList, allSubGenreList],
  );

  // 카테고리 선택 시 상태 업데이트
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);

    // AI모델 변경
    const newFilterAiModelList = allAiModelList?.filter((item) => item?.cate_cd === value);
    setFilterAiModelList(newFilterAiModelList);
    handleAiModelChange(newFilterAiModelList[0]?.cert_yn, newFilterAiModelList[0]?.ai_model_cd);

    // 장르 변경
    const newFilterGenreList = allGenreList?.filter((item) => item?.parent_cd === value);
    setFilterGenreList(newFilterGenreList);
    handleGenreChange(newFilterGenreList[0]?.cd);

    // 판매구분, 태그 초기화
    setSelectedPay(promptInitValue.sale_div);
    setTags([]); // 태그

    // 기존 첨부파일 삭제
    const newDelFile = attachFile?.filter((file) => file.status == 'done').map((file) => file.url);
    setDelFileCaches(newDelFile);
    setAttachFile([]);

    form.setFieldsValue({
      sale_div: promptInitValue.sale_div, // 판매 구분
      sale_amt: promptInitValue.sale_amt, // 판매 금액
      ...(value === 'CATE003' && {
        prpt_remark: '',
        test_prpt: '',
        text_prpt: '',
      }),
      ...(value !== 'CATE003' && {
        music_org_link: '',
      }),
    });
  };

  // ai 모델 변경시
  const handleAiModelChange = (certyn, value) => {
    setSelectedAiModel(value);
    form.setFieldValue('ai_model_cd', value);

    // 인증여부 설정
    const newCertyn = certyn === 'Y';
    setCertYn(newCertyn);

    // 인증여부가 Y이면 유저 인증 체크
    if (newCertyn) aiModelCertChk(value);
  };

  // 장르 변경시
  const handleGenreChange = (value) => {
    setSelectedGenre(value);
    form.setFieldValue('genre_cd', value);

    // 서브장르 변경
    const newFilterSubGenreList = allSubGenreList?.filter((item) => item?.parent_cd === value);
    setFilterSubGenreList(newFilterSubGenreList);
    handleSubGenreChange(newFilterSubGenreList[0].cd);
  };

  // 서브장르 변경시
  const handleSubGenreChange = (value) => {
    setSelectedSubGenre(value);
    form.setFieldValue('sub_genre_cd', value);
  };

  // 판매구분
  const handleSaleDivChange = (newValue) => {
    setSelectedPay(newValue);
    if (newValue === 'PAID') {
      // 유료인 경우 판매금액은 1000 으로 set
      form.setFieldsValue({ sale_amt: 1000 });
      // 사업자등록 안되어 있으면, 사업자등록 모달창 열기
      if (!isBusinessAccount) onSellerModalOpen();
    } else {
      // 무료인 경우 판매금액은 0 으로 set
      form.setFieldsValue({ sale_amt: 0 });
    }
  };

  // 유저 인증 체크
  const aiModelCertChk = async (ai_model_cd) => {
    try {
      const { data } = await api.aiModelCertChk({
        ai_model_cd: ai_model_cd,
        mem_key: memKey,
      });

      if (data?.returnStatus === 'SUCCESS') {
        setIsCerted(true); // 유저 인증 완료
        return true;
      } else {
        message.error(data?.returnMessage);
        setIsCerted(false); // 유저 인증 실패
        onAiIsModalOpen(); // 팝업창 오픈
        return false;
      }
    } catch (error) {
      console.error('error');
      return false;
    }
  };

  const handleAiInfoFlag = () => {
    setAiInfoFlag(!aiInfoFlag);
    if (!!aiInfoFlag) {
      // aiInfoFlag가 false면 입력값 제거
      form.setFieldsValue({
        prpt_sample: null,
        prpt_lyrics: null,
        prpt_step: null,
        prpt_seed: null,
        clip_gdc: null,
      });
    }
  };

  // 태그
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addTag(inputValue);
      setTimeout(() => {
        form.setFieldsValue({ tag: '' });
      }, 0);
    }
  };

  const addTag = (tag) => {
    if (tag && tags.length < 10 && !tags.includes(tag)) {
      setTags([...tags, tag]);
      setInputValue('');
    }
  };

  const handleDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  // 업로드 전
  const beforeUpload = (file, fileType, errorMessage) => {
    const validate = file.type.indexOf(fileType);

    if (validate < 0) {
      message.error(errorMessage);
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  // 파일 업로드
  const handleFileChange = (newFile, flag) => {
    if (newFile.file.size > uploadSize * 1024 * 1024) {
      message.error('각 파일의 크기는 ' + uploadSize + 'MB를 초과할 수 없습니다.');
      return;
    }
    if (flag === 'T') {
      setThumFile([newFile.file]);
      const reader = new FileReader();
      reader.readAsDataURL(newFile.file);
    } else if (flag === 'D') {
      setAttachFile([newFile.file]);
    } else {
      setAttachFile((prev) => [...prev, newFile.file]);
    }
  };

  // 이미지 파일 업로드 (업로드된 파일 변경용)
  const handleImageFileChange = useCallback(
    (newFile, index) => {
      if (newFile.file.size > uploadSize * 1024 * 1024) {
        message.error('각 파일의 크기는 ' + uploadSize + 'MB를 초과할 수 없습니다.');
        return;
      }
      const newAttachFile = Array.from(attachFile);
      newAttachFile[index] = newFile.file;
      setAttachFile(newAttachFile);
    },
    [attachFile],
  );

  // 파일 삭제 컨트롤
  const handleFileRemove = (e, file, flag) => {
    e.stopPropagation();
    e.preventDefault();

    // 판매중/판매중지 상태이면 삭제할 수 없음
    if (isOnSale) return;

    if (flag == 'T') {
      setThumFile([]);
    } else {
      setAttachFile((prev) => prev.filter((item) => item?.uid !== file.uid));
    }

    if (file.status == 'done') setDelFileCaches([...delFileCaches, file.url]);
  };

  // 이미지 파일 삭제 (업로드된 파일 삭제용)
  const handleImageFileRemove = (e, file, flag) => {
    e.stopPropagation();
    e.preventDefault();

    // 판매중/판매중지 상태이면 삭제할 수 없음
    if (isOnSale) return;

    setAttachFile((prev) => prev.filter((item) => item?.uid !== file.uid));

    if (file.status == 'done') setDelFileCaches([...delFileCaches, file.url]);
  };

  // 밸리데이션
  const onFinishFailed = ({ errorFields }) => {
    // 첫 번째 오류 필드로 포커스 이동
    if (errorFields.length > 0) {
      form.scrollToField(errorFields[0].name, {
        behavior: 'smooth', // 스크롤 애니메이션 옵션
        block: 'center',
      });
    }
  };

  // 저장
  const onFinish = async (saveData) => {
    try {
      await form.validateFields();
    } catch (error) {
      message.error('필수 항목을 모두 입력해주세요.');
      return false;
    }
    if (await window.confirm(saveType === '01' ? '저장하시겠습니까?' : saveData.cate_cd === 'CATE003' ? '등록하시겠습니까?' : '판매요청 하시겠습니까?')) {
      // saveData가 undefined 인경우 null로 변경
      const newSaveData = {};
      for (const key in saveData) {
        newSaveData[key] = saveData[key] ?? null;
      }

      // validation
      if (newSaveData.prpt_stat === '10' || (newSaveData.cate_cd === 'CATE003' && newSaveData.prpt_stat === '30')) {
        // 저장시
        // 유료이고 사업자등록번호 확인이 안되었을 때
        if (selectedPay !== 'FREE' && !isBusinessAccount) {
          onSellerModalOpen();
          message.error('사업자등록번호를 등록하셔야 판매가 가능합니다.');
          return;
        }
        // 인증여부가 true인데 유저가 ai 인증을 완료하지 못했을 때
        if (certYn && !isCerted) {
          message.error('AI모델 인증이 완료되어야 판매가 가능합니다.');
          onAiIsModalOpen(); // 팝업창 오픈
          return;
        }
        // 이미지 첨부필수
        if (newSaveData.cate_cd === 'CATE001' && !(attachFile.length === 4)) {
          message.error('이미지를 5장 업로드 해주세요.');
          return;
        }
        // 음원 첨부필수
        if (newSaveData.cate_cd === 'CATE003' && newSaveData.prpt_stat === '30' && !(attachFile.length === 1)) {
          message.error('첨부파일을 업로드 해주세요.');
          return;
        }
        // 이미지 대표이미지 필수
        if (newSaveData.cate_cd === 'CATE001' && !thumFile.length > 0) {
          message.error('대표이미지는 필수값입니다.');
          return;
        }
        // 동영상 대표이미지 필수
        if (newSaveData.cate_cd === 'CATE002' && !thumFile.length > 0) {
          message.error('대표이미지는 필수값입니다.');
          return;
        }
        if (newSaveData.cate_cd === 'CATE002' && !newSaveData.music_org_link) {
          message.error('대표이미지 하단의 링크를 입력해주세요.');
          return false;
        }
        // 태그 필수
        if (!inputValue && !tags.length) {
          message.error('태그는 필수값입니다.');
          return false;
        }
      }

      if (attachFile.length > 0) {
        // if (!(await dataChk())) {
        //   if (await window.confirm('사용 가능 데이터가 부족합니다. 데이터를 추가하시겠습니까?')) {
        //     navigate(PROFILE_PAGE, {
        //       state: {
        //         mem_email: memEmail,
        //         mem_key: memKey,
        //       },
        //     });
        //   }
        //   return;
        // }

        const totalSize = attachFile.reduce((acc, file) => acc + file.size, 0);
        if (totalSize > uploadSize * 1024 * 1024) {
          message.error('업로드 파일의 크기는 총' + uploadSize + 'MB를 초과할 수 없습니다.');
        }
      }

      const formData = new FormData();

      // 대표이미지
      let savedThum = '';
      if (thumFile.length > 0) {
        const saveThumFile = thumFile?.filter((file) => file.status !== 'done')[0]; // 새로운 대표이미지
        formData.append(`thumFile`, saveThumFile);
        savedThum = thumFile?.filter((file) => file.status == 'done')[0]?.url; // 기존 대표이미지
      } else {
        // 대표이미지가 존재하지 않으면 기본 이미지 생성
        savedThum = getDefaultThum(newSaveData.cate_cd);
      }

      // 첨부파일
      attachFile
        ?.filter((file) => file.status !== 'done')
        .forEach((file, index) => {
          formData.append(`attachFiles`, file.originFileObj || file);
        });

      // 삭제 파일
      delFileCaches.forEach((delFile) => {
        console.log(delFile);
        formData.append('delFiles', delFile);
      });

      // 저장데이터
      const saveFormData = {
        ...newSaveData,
        prpt_id: prpt_id,
        sale_agree: newSaveData?.sale_agree ? 'Y' : 'N',
        biz_confirm: newSaveData?.biz_confirm ? 'Y' : 'N',
        tag: tags.join(',') || inputValue,
        mem_key: memKey,
        thum_path: savedThum,
        file_grp_seq: fileGrpSeq,
        ...(selectedCategory === 'CATE003' && {
          sale_div: 'FREE',
          sale_amt: 0,
        }),
      };

      formData.append('saveData', new Blob([JSON.stringify(saveFormData)], { type: 'application/json' }));

      try {
        await api
          .salesPrompt(formData)
          .then((response) => {
            if (response.data?.returnStatus === 'success') {
              Modal.warning({
                content: response.data?.returnMessage,
                onOk:
                  newSaveData.prpt_stat === '01'
                    ? navigate(PROMPT_MANAGEMENT, { replace: true })
                    : state?.prpt_id
                      ? navigate(PROMPT_DETAIL, { state: { prpt_id: state?.prpt_id }, replace: true })
                      : navigate(MARKET_SALES_HOME, { replace: true }),
                okText: '확인',
              });
            } else if (response.data?.returnStatus === 'error') {
              message.error(response.data?.returnMessage);
            }
          })
          .error((error) => {
            console.log('에러가 발생했습니다.\n' + error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  // 이용약관 모달창 열기
  const onSalesTermsOpen = () => {
    setSalesTermsVisible(true);
  };

  // 이용약관 모달창 닫기
  const onSalesTermsClose = () => {
    setSalesTermsVisible(false);
  };

  // 사업자등록번호 등록 모달창 열기
  const onSellerModalOpen = () => {
    setIsSellerModalVisible(true);
  };

  // 사업자등록번호 등록 모달창 닫기
  const onSellerModalClose = () => {
    setIsSellerModalVisible(false);
  };

  // AI모델 인증증 모달창 열기
  const onAiIsModalOpen = () => {
    setAiIsModalVisible(true);
  };

  // 사업자등록번호 등록 모달창 닫기
  const onAiIsModalClose = () => {
    setAiIsModalVisible(false);
  };

  // 사업자등록번호 등록확인
  const businessNoChk = async () => {
    try {
      const { data: isOk } = await api.businessNoChk({ mem_key: memKey });
      if (isOk) setIsBusinessAccount(true);
    } catch (error) {
      console.error('사업자등록 인증 error', error);
    }
  };

  // 데이터 체크 ( 첨부파일만 해당 / 대표이미지 제외 )
  const dataChk = async () => {
    const { data } = await api.getMemUsedData({ mem_key: memKey });

    const total_data_size = totalDataSize; // 총 데이터
    const avl_data = (total_data_size - data) * 1024 * 1024; // 사용가능 데이터
    const save_data = attachFile.reduce((acc, curr) => {
      // 업로드한 데이터
      return acc + curr.size;
    }, 0);

    // 사용가능 데이터 - 업로드 데이터가 0보다 크면 true
    return avl_data - save_data > 0;
  };

  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'SALE_DIV' }); // 판매구분 (무료/유료)
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' }); // 카테고리
      const response3 = await api.fetchEqualCommonGroupCode({ grp_cd: 'GENRE' }); // 장르
      const response4 = await api.fetchEqualCommonGroupCode({ grp_cd: 'SUB_GENRE' }); // 서브장르
      const response5 = await api.getAiModelByCate(); // AI모델

      setSaleDivList(response1.data);
      setCategoryList(response2.data);
      setAllAiModelList(response5.data);
      setAllGenreList(response3.data);
      setAllSubGenreList(response4.data);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };

  useEffect(() => {
    getCommonCode(); // 공통코드 조회
  }, []);

  // 사업자등록 확인
  useEffect(() => {
    if (!isBusinessAccount && !isSellerModalVisible) businessNoChk();
  }, [isBusinessAccount, isSellerModalVisible]);

  // 수정일 경우 작성항목 조회
  useEffect(() => {
    if (state && state?.prpt_id && allAiModelList.length && allGenreList.length && allSubGenreList.length) getSalesPrompt({ mem_key: state?.mem_key || memKey, prpt_id: state?.prpt_id });
  }, [state, memKey, allAiModelList, allGenreList, allSubGenreList]);

  // 수정이 아니라 등록인 경우 or 디폴트 설정 안된 경우
  useEffect(() => {
    if (allAiModelList.length && allGenreList.length && allSubGenreList.length && selectedCategory && (!state || !state?.prpt_id) && isDoneConfig) {
      // AI모델 디폴트 설정
      const defaultFilterAiModelList = allAiModelList?.filter((item) => item?.cate_cd === selectedCategory);
      setFilterAiModelList(defaultFilterAiModelList);
      // 장르 디폴트 설정
      const defaultFilterGenreList = allGenreList?.filter((item) => item?.parent_cd === selectedCategory);
      setFilterGenreList(defaultFilterGenreList);
      // 서브장르 디폴트 설정
      const defaultFilterSubGenreList = allSubGenreList?.filter((item) => item?.parent_cd === defaultFilterGenreList[0]?.cd);
      setFilterSubGenreList(defaultFilterSubGenreList);
      // 디폴트 AI모델 리스트의 첫번째 값, 디폴트 장르 리스트의 첫번째 값
      const newAiModelCd = defaultFilterAiModelList[0]?.ai_model_cd;
      const newCertYn = defaultFilterAiModelList[0]?.cert_yn === 'Y';
      setCertYn(newCertYn);

      setSelectedAiModel(newAiModelCd);
      form.setFieldsValue({ ai_model_cd: newAiModelCd });

      setSelectedGenre(defaultFilterGenreList[0]?.cd);
      form.setFieldsValue({ genre_cd: defaultFilterGenreList[0]?.cd });

      setSelectedSubGenre(defaultFilterSubGenreList[0]?.cd);
      form.setFieldsValue({ sub_genre_cd: defaultFilterSubGenreList[0]?.cd });

      // 인증여부가 Y이면 유저 인증 체크
      if (newCertYn) aiModelCertChk(newAiModelCd);

      // 디폴트 설정 완료
      setIsDoneConfig(false);
      // if ((!state || !state?.prpt_id) && isDoneConfig) {
      // }
    }
  }, [selectedCategory, allAiModelList, allGenreList, allSubGenreList, state, isDoneConfig]);

  // AI 결과물 등록 컨포넌트
  const ResultComponent = ({ cateType }) => {
    const handlePreview = (e, url) => {
      e.stopPropagation();
      e.preventDefault();

      if (url) {
        setPreviewImage(url);
        setPreviewOpen(true);
      }
    };

    switch (cateType) {
      case 'CATE001': //이미지
        return (
          <div className="flexRowStart info">
            {/* 이미지 미리보기 */}
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}

            {/* 대표이미지 */}
            <Upload.Dragger
              accept={uploadImageExtension}
              fileList={thumFile}
              className="flexColCenter plusBox"
              onChange={(file) => handleFileChange(file, 'T')}
              beforeUpload={(file) => beforeUpload(file, 'image', '대표이미지는 이미지만 업로드 가능합니다.')} // 실제 업로드 방지
              showUploadList={false} // 기본 리스트 표시 비활성화
              disabled={isOnSale}
            >
              {thumFile.length === 0 ? (
                <div className="flexColCenter">
                  <div className="plus flexColCenter">
                    <PlusOutlined />
                  </div>

                  {width > 900 && (
                    <p style={{ fontSize: `11px` }}>
                      대표 이미지를 등록하거나 <br /> 드래그 & 드롭해주세요.
                      <br />
                      (16:9 사이즈 권장)
                    </p>
                  )}
                </div>
              ) : (
                <div className="flexColCenter">
                  {thumFile.map((file, index) => (
                    <div key={index} className="flexColCenter imageContainer">
                      {/* 미리보기 아이콘 */}
                      <img src={IMAGES.FILE_EXPAN_ICON} alt="expand icon" className="expandIcon" onClick={(e) => handlePreview(e, URL.createObjectURL(file))} />

                      {/* 변경 아이콘 */}
                      <img src={IMAGES.FILE_CHANGE_ICON} alt="change icon" className="changeIcon" />

                      {/* 삭제 아이콘 */}
                      <img src={IMAGES.FILE_TRASH_ICON} alt="trash icon" className="trashIcon" onClick={(e) => handleFileRemove(e, file, 'T')} />

                      {/* 업로드된 이미지 */}
                      <img src={URL.createObjectURL(file)} alt="" className="uploadthum" />
                    </div>
                  ))}
                </div>
              )}
            </Upload.Dragger>

            {/* 업로드된 추가 이미지 */}
            {attachFile.map((file, index) => (
              <Upload.Dragger
                accept={uploadImageExtension}
                fileList={attachFile}
                className="flexColCenter plusBox"
                onChange={(file) => handleImageFileChange(file, index)}
                beforeUpload={(file) => beforeUpload(file, 'image', '카테고리에 맞는 파일을 선택해주세요.')} // 실제 업로드 방지
                showUploadList={false} // 기본 리스트 표시 비활성화
                disabled={isOnSale}
              >
                <div className="flexColCenter plusBox" key={index}>
                  <div key={index} className="flexColCenter imageContainer" style={{ position: 'relative' }}>
                    {/* 미리보기 아이콘 */}
                    <img src={IMAGES.FILE_EXPAN_ICON} alt="expand icon" className="expandIcon" onClick={(e) => handlePreview(e, URL.createObjectURL(file))} />

                    {/* 변경 아이콘 */}
                    <img src={IMAGES.FILE_CHANGE_ICON} alt="change icon" className="changeIcon" />

                    {/* 삭제 아이콘 */}
                    <img src={IMAGES.FILE_TRASH_ICON} alt="trash icon" className="trashIcon" onClick={(e) => handleImageFileRemove(e, file, 'A')} />

                    {/* 업로드된 이미지 */}
                    <img src={URL.createObjectURL(file)} alt="" className="uploadthum" />
                  </div>
                </div>
              </Upload.Dragger>
            ))}

            {/* 업로드할 추가 이미지 */}
            {attachFile.length >= 4 ? null : (
              <Upload.Dragger
                accept={uploadImageExtension}
                multiple={true}
                maxCount={4}
                fileList={attachFile}
                className="flexColCenter plusBox"
                onChange={(file) => handleFileChange(file, 'A')}
                beforeUpload={(file) => beforeUpload(file, 'image', '카테고리에 맞는 파일을 선택해주세요.')} // 실제 업로드 방지
                showUploadList={false} // 기본 리스트 표시 비활성화
                disabled={isOnSale}
              >
                <div className="flexColCenter">
                  <div className="plus flexColCenter">
                    <PlusOutlined />
                  </div>
                  {width > 900 && (
                    <p style={{ fontSize: `11px` }}>
                      추가 이미지를 등록하거나
                      <br />
                      드래그&드롭해 주세요.
                      <br />
                      (16:9 사이즈 권장){' '}
                    </p>
                  )}
                </div>
              </Upload.Dragger>
            )}
          </div>
        );
      case 'CATE003':
        return (
          <>
            <div className="flexRowStart info">
              {/* 이미지 미리보기 */}
              {previewImage && (
                <Image
                  wrapperStyle={{ display: 'none' }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}

              {/* 첨부 파일 버튼*/}
              <div className="flexColStart info music">
                <Upload.Dragger
                  accept={uploadMusicExtension}
                  fileList={attachFile}
                  className="fileBtnBox"
                  onChange={(file) => handleFileChange(file, 'D')}
                  beforeUpload={(file) => beforeUpload(file, 'audio', '카테고리에 맞는 파일을 선택해주세요.')} // 실제 업로드 방지
                  showUploadList={false} // 기본 리스트 표시 비활성화
                  disabled={isOnSale}
                >
                  <div className="flexRowStart">
                    <button className="btn-dark" onClick={(e) => e.preventDefault()}>
                      음원 파일 등록
                    </button>
                  </div>
                </Upload.Dragger>

                {/* 첨부 파일 박스*/}
                <Upload.Dragger
                  accept={uploadMusicExtension}
                  fileList={attachFile}
                  className="flexColCenter plusBox"
                  onChange={(file) => handleFileChange(file, 'D')}
                  beforeUpload={(file) => beforeUpload(file, 'audio', '카테고리에 맞는 파일을 선택해주세요.')} // 실제 업로드 방지
                  showUploadList={false} // 기본 리스트 표시 비활성화
                  disabled={isOnSale}
                >
                  {attachFile.length === 0 ? (
                    <div className="flexColCenter">
                      <div className="plus flexColCenter">
                        <PlusOutlined />
                      </div>
                      <p style={{ fontSize: `11px` }}>음원 파일을 등록하거나 드래그 & 드롭해주세요.</p>
                    </div>
                  ) : (
                    <>
                      {attachFile.map((file) => (
                        <p key={file.uid}>
                          {file.name}
                          <button onClick={(e) => handleFileRemove(e, file, 'A')} style={{ cursor: isOnSale ? 'not-allowed' : 'pointer' }} />
                        </p>
                      ))}
                    </>
                  )}
                </Upload.Dragger>
              </div>

              {/* 대표이미지 */}
              <div className="flexColStart thum">
                <h1>대표이미지 등록</h1>
                <Upload.Dragger
                  accept={uploadImageExtension}
                  fileList={thumFile}
                  className="flexColCenter plusBox"
                  onChange={(file) => handleFileChange(file, 'T')}
                  beforeUpload={(file) => beforeUpload(file, 'image', '대표이미지는 이미지만 업로드 가능합니다.')} // 실제 업로드 방지
                  showUploadList={false} // 기본 리스트 표시 비활성화
                  disabled={isOnSale}
                >
                  {thumFile.length === 0 ? (
                    <div className="flexColCenter">
                      <div className="plus flexColCenter">
                        <PlusOutlined />
                      </div>
                      <p style={{ fontSize: `11px` }}>
                        대표이미지를 등록하거나 <br />
                        드래그 & 드롭 해주세요.
                        <br />
                        (권장 사이즈 500 x 500 px)
                      </p>
                    </div>
                  ) : (
                    <div className="flexColCenter imgArea">
                      {thumFile.map((file, index) => (
                        <div key={index} className="flexColCenter imageContainer">
                          {/* 미리보기 아이콘 */}
                          <img src={IMAGES.FILE_EXPAN_ICON} alt="expand icon" className="expandIcon" onClick={(e) => handlePreview(e, URL.createObjectURL(file))} />

                          {/* 변경 아이콘 */}
                          <img src={IMAGES.FILE_CHANGE_ICON} alt="change icon" className="changeIcon" />

                          {/* 삭제 아이콘 */}
                          <img src={IMAGES.FILE_TRASH_ICON} alt="trash icon" className="trashIcon" onClick={(e) => handleFileRemove(e, file, 'T')} />

                          {/* 업로드된 이미지 */}
                          <img src={URL.createObjectURL(file)} alt="" className="uploadthum" />
                        </div>
                      ))}
                    </div>
                  )}
                </Upload.Dragger>
              </div>
            </div>

            {/* 원본 파일 링크 */}
            <div className="flexColStart info">
              <h1>AI 음원 원본 링크</h1>
              <Form.Item name="music_org_link" className="w-100" rules={saveType === '01' ? [] : [{ required: true, message: '링크를 입력하세요' }]}>
                <Input placeholder="http://" disabled={isOnSale} />
              </Form.Item>
            </div>
          </>
        );
      case 'CATE004':
        return (
          <div className="flexRowStart info textUpload">
            <Form.Item name="text_prpt" className="w-100" rules={saveType === '01' ? [] : [{ required: true, message: '텍스트 프롬프트 아웃풋을 입력하세요.' }]}>
              <TextArea placeholder="텍스트 프롬프트 아웃풋을 입력하세요." autoSize={{ minRows: 5, maxRows: 15 }} disabled={isOnSale} />
            </Form.Item>
          </div>
        );
      default:
        return (
          <>
            <div className="flexRowStart info">
              {/* 이미지 미리보기 */}
              {previewImage && (
                <Image
                  wrapperStyle={{ display: 'none' }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) => !visible && setPreviewImage(''),
                  }}
                  src={previewImage}
                />
              )}
              {cateType === 'CATE002' && (
                <div className="flexColStart thum">
                  <h1>대표이미지 등록</h1>
                  <Upload.Dragger
                    accept={uploadImageExtension}
                    fileList={thumFile}
                    className="flexColCenter plusBox"
                    onChange={(file) => handleFileChange(file, 'T')}
                    beforeUpload={(file) => beforeUpload(file, 'image', '대표이미지는 이미지만 업로드 가능합니다.')} // 실제 업로드 방지
                    showUploadList={false} // 기본 리스트 표시 비활성화
                    disabled={isOnSale}
                  >
                    {thumFile.length === 0 ? (
                      <div className="flexColCenter">
                        <div className="plus flexColCenter">
                          <PlusOutlined />
                        </div>
                        <p style={{ fontSize: `11px` }}>
                          대표이미지 등록 또는 <br /> 드래그&드롭해 주세요.
                          <br />
                          (16:9 사이즈 권장)
                        </p>
                      </div>
                    ) : (
                      <div className="flexColCenter imgArea">
                        {thumFile.map((file, index) => (
                          <div key={index} className="flexColCenter imageContainer">
                            {/* 미리보기 아이콘 */}
                            <img src={IMAGES.FILE_EXPAN_ICON} alt="expand icon" className="expandIcon" onClick={(e) => handlePreview(e, URL.createObjectURL(file))} />

                            {/* 변경 아이콘 */}
                            <img src={IMAGES.FILE_CHANGE_ICON} alt="change icon" className="changeIcon" />

                            {/* 삭제 아이콘 */}
                            <img src={IMAGES.FILE_TRASH_ICON} alt="trash icon" className="trashIcon" onClick={(e) => handleFileRemove(e, file, 'T')} />

                            {/* 업로드된 이미지 */}
                            <img src={URL.createObjectURL(file)} alt="" className="uploadthum" />
                          </div>
                        ))}
                      </div>
                    )}
                  </Upload.Dragger>
                </div>
              )}
            </div>

            {/* 원본 파일 링크 */}
            <div className="flexRowStart info">
              <Form.Item name="music_org_link" className="w-100" rules={saveType === '01' ? [] : [{ required: true, message: '링크를 입력하세요' }]}>
                <Input placeholder="http://" disabled={isOnSale} />
              </Form.Item>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <article className="flexColCenter form" id="promptSales">
        <div className="rsWrapper flexColCenter">
          <Form form={form} initialValues={promptInitValue} onFinish={(values) => onFinish(values, 'formSubmit')} onFinishFailed={onFinishFailed}>
            <Form.Item
              name="sale_agree"
              className="flexRowCenter essentialBox"
              valuePropName="checked"
              rules={
                saveType == '01'
                  ? []
                  : [
                      {
                        validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('미동의시 접수가 불가능합니다.'))),
                      },
                    ]
              }
            >
              <Checkbox>
                (필수) 마켓플레이스 판매자 이용약관 동의
                <button type="button" onClick={onSalesTermsOpen}>
                  (<span className="blueSpan">보기</span>)
                </button>
              </Checkbox>
            </Form.Item>

            <div className="flexRowCenter titleBox">
              <div className="flexRowCenter title">
                <img src={profileIcon} />
                <h1>프롬프트 정보</h1>
              </div>
            </div>
            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={promptIcon} />
                <h1 className="basicEssential">구분</h1>
              </div>

              <div className="flexColCenter content">
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <Form.Item name="cate_cd" rules={[{ required: true, message: '카테고리를 선택하세요' }]}>
                      <Radio.Group onChange={(e) => handleCategoryChange(e.target.value)} disabled={currentPrptStat === '01' ? false : true} defaultValue={selectedCategory}>
                        {(categoryList || []).map((option, index) => (
                          <Radio key={index} value={option.cd}>
                            {option.cd_nm}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1>AI 모델</h1>
                    <div className="flexRowStart">
                      <Form.Item name="ai_model_cd" normalize={(value) => value ?? null} rules={saveType == '01' ? [] : [{ required: true, message: 'AI모델을 선택하세요' }]}>
                        <Radio.Group onChange={(e) => handleAiModelChange(e.target.certyn, e.target.value)} disabled={currentPrptStat === '01' ? false : true} defaultValue={selectedAiModel}>
                          {filterAiModelList.map((option, index) => (
                            <Radio
                              key={index}
                              value={option.ai_model_cd}
                              certyn={option.cert_yn} // 인증여부
                            >
                              {option.ai_model_nm}
                            </Radio>
                          ))}{' '}
                          <span>
                            {selectedAiModel == 'ETC' && (
                              <Form.Item name="etc_nm" normalize={(value) => value ?? null} rules={saveType == '01' ? [] : [{ required: true, message: '기타 AI모델명을 입력하세요' }]}>
                                <Input placeholder="기타 AI명" disabled={currentPrptStat === '01' ? false : true} />
                              </Form.Item>
                            )}
                          </span>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                    {certYn && isCerted && (
                      // ai 인증을 해야 상업적 이용 확인
                      <>
                        <AiModelCertModal visible={isAiModalVisible} onClose={onAiIsModalClose} aiModelCd={selectedAiModel} />
                        <Form.Item
                          name="biz_confirm"
                          valuePropName="checked"
                          rules={
                            saveType == '01'
                              ? []
                              : [
                                  {
                                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('미동의시 접수가 불가능합니다.'))),
                                  },
                                ]
                          }
                        >
                          <Checkbox
                            style={{
                              fontSize: `14px`,
                            }}
                          >
                            <p>상업적 이용 확인</p>
                          </Checkbox>
                        </Form.Item>
                      </>
                    )}
                  </div>
                </div>
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1>카테고리</h1> {/*장르*/}
                    <div className="flexRowStart">
                      <Form.Item name="genre_cd" rules={saveType == '01' ? [] : [{ required: true, message: '카테고리를 선택하세요.' }]}>
                        <Radio.Group onChange={(e) => handleGenreChange(e.target.value)} disabled={currentPrptStat === '01' ? false : true} defaultValue={selectedGenre}>
                          {filterGenreList.map((option, index) => (
                            <Radio key={index} value={option.cd}>
                              {option.cd_nm}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {filterSubGenreList.length > 0 && (
                  <div className="flexColCenter box">
                    <div className="flexColStart info">
                      <h1>서브 카테고리</h1> {/* 서브 장르*/}
                      <div className="flexRowStart">
                        <Form.Item name="sub_genre_cd" rules={saveType == '01' ? [] : [{ required: true, message: '서브 카테고리를 선택하세요.' }]}>
                          <Radio.Group onChange={(e) => handleSubGenreChange(e.target.value)} disabled={currentPrptStat === '01' ? false : true} defaultValue={selectedSubGenre}>
                            {filterSubGenreList.map((option, index) => (
                              <Radio key={index} value={option.cd}>
                                {option.cd_nm}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}
                {selectedCategory === 'CATE003' && (
                  <div className="flexColCenter box">
                    <div className="flexColStart info">
                      <h1>용도</h1>
                      <div className="flexRowStart">
                        <Form.Item name="use_tp">
                          <Radio.Group defaultValue={'2'}>
                            <Radio value='1'>음원</Radio><Checkbox><Text>아티스트 영상사용</Text></Checkbox>
                            <Radio value='2'>음원공간</Radio><Select options={[{value: 'PM', label: '공개음원'}, {value: 'MM', label: '내 음원'}]}></Select>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={aiIcon} />
                <h1>AI모델 정보 및 설정</h1>
                <div className="addBox">
                  <Flex>
                    <Checkbox onChange={handleAiInfoFlag} disabled={isOnSale} />
                    <h4>정보 항목 추가</h4>
                  </Flex>
                  {/* <button id="darkGrey" onClick={handleAiInfoFlag} type="button">
                    <PlusOutlined />
                    정보 항목 추가
                  </button> */}
                </div>
              </div>

              <div className="flexColStart content">
                <div className="flexColCenter box third">
                  <div className="flexColStart info">
                    <h1 className="basicEssential">버전</h1>
                    <div className="flexRowStart">
                      <Form.Item name="prpt_ver" className="w-100" rules={saveType === '01' ? [] : [{ required: true, message: '버전을 입력하세요' }]}>
                        <Input placeholder="해당 프롬프트를 적용한 AI 모델의 버전을 입력하세요." disabled={isOnSale} maxLength={5} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                {aiInfoFlag && (
                  <>
                    <div className="flexRowBetween">
                      <div className="flexColCenter box">
                        <div className="flexColStart info">
                          <h1>정보 항목</h1>
                          <Form.Item name="prpt_sample" className="w-100">
                            <TextArea placeholder="해당 AI 모델의 버전 외의 필수 정보가 있을 경우 입력하세요." autoSize={{ minRows: 5, maxRows: 15 }} disabled={isOnSale} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={profileIcon} />
                <h1>기본정보</h1>
              </div>

              <div className="flexColStart content">
                {selectedCategory !== 'CATE003' ? (
                  <div className="flexColCenter box third">
                    <div className="flexColStart info">
                      <h1 className="basicEssential">
                        판매 가격
                        <Tooltip
                          title={
                            <div>
                              <div>
                                무료 멤버십과 사업자 정보 미등록 유료 멤버십은 판매가격을 설정할 수 없으며, 무료 공유만 가능합니다.<br></br>
                                무료 공유시에도 프롬프트 엔지니어 랭킹 점수는 획득할 수 있습니다.
                              </div>

                              <Button onClick={() => navigate(PROFILE_PAGE, { state: { mem_email: memEmail, mem_key: memKey } })}>내 정보 확인하기</Button>
                            </div>
                          }
                          placement="right"
                        >
                          <span style={{ cursor: 'pointer' }}>
                            <QuestionCircleOutlined style={{ marginLeft: '8px', cursor: 'pointer' }} />
                          </span>
                        </Tooltip>
                      </h1>
                      <div className="flexRowBetween half">
                        <Form.Item name="sale_div" className="w-100">
                          <Select options={saleDivList} fieldNames={{ label: 'cd_nm', value: 'cd' }} onChange={handleSaleDivChange} disabled={isOnSale} />
                        </Form.Item>
                        <Form.Item name="sale_amt" rules={saveType === '01' ? [] : [{ required: selectedPay !== 'FREE', message: '판매가격을 입력하세요' }]}>
                          <InputNumber
                            className="w-100"
                            disabled={selectedPay === 'FREE' || isOnSale}
                            min={selectedPay === 'FREE' ? 0 : 1000}
                            parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                            controls={false}
                            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          />{' '}
                          원
                        </Form.Item>{' '}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1 className="basicEssential">제목</h1>
                    <Form.Item
                      name="prpt_title"
                      className="w-100"
                      rules={[
                        { required: true, message: '제목을 입력하세요' },
                        () => ({
                          validator(_, value) {
                            if (!value || value.length <= 40) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('최대 40글자를 초과할 수 없습니다.'));
                          },
                        }),
                      ]}
                    >
                      <Input id="prpt_title" placeholder="제목을 입력하세요." />
                    </Form.Item>
                  </div>
                </div>
                <div className="flexColCenter box">
                  <div className="flexColStart info">
                    <h1 className="basicEssential">소개</h1>
                    <Form.Item name="prpt_desc" className="w-100" rules={saveType === '01' ? [] : [{ required: true, message: '소개를 입력하세요' }]}>
                      <TextArea placeholder={'잠재 구매자에게 어필할 수 있는 소개 및 홍보글을 작성하세요.'} autoSize={{ minRows: 5, maxRows: 15 }} />
                    </Form.Item>
                    {selectedCategory === 'CATE003' ? (
                      <>
                        <h1>가사</h1>
                        <Form.Item name="prpt_lyrics" className="w-100">
                          <TextArea placeholder={'가사를 입력하세요.'} autoSize={{ minRows: 5, maxRows: 15 }} />
                        </Form.Item>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {selectedCategory !== 'CATE003' ? (
              <div className="flexRowBetween info-area">
                <div className="title flexRowStart">
                  <img src={profileIcon} />
                  <h1>세부 정보</h1>
                </div>

                <div className="flexColCenter content">
                  <div className="flexColCenter box">
                    <div className="flexColStart info">
                      <h1 className="basicEssential">프롬프트 활용 가이드</h1>
                      <Form.Item name="prpt_remark" className="w-100" rules={saveType === '01' ? [] : [{ required: true, message: '프롬프트 활용 가이드를 입력하세요' }]}>
                        <TextArea placeholder={remarkPlaceholder} autoSize={{ minRows: 5, maxRows: 15 }} />
                      </Form.Item>
                    </div>
                  </div>
                  {selectedCategory !== 'CATE002' ? (
                    <div className="flexColCenter box">
                      <div className="flexColStart info">
                        <h1 className="basicEssential">프롬프트</h1>
                        <Form.Item name="test_prpt" className="w-100" rules={saveType === '01' ? [] : [{ required: true, message: '프롬프트를 입력하세요' }]}>
                          <TextArea placeholder="프롬프트를 입력하세요." autoSize={{ minRows: 5, maxRows: 15 }} />
                        </Form.Item>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="flexRowBetween info-area tag">
              <div className="title flexRowStart">
                <img src={tagIcon} />
                <h1 className="basicEssential">태그</h1>
              </div>

              <div className="flexColCenter content">
                <div className="flexColCenter box">
                  <div className="flexColStart info tagBox">
                    <span className="tagInput">
                      <Form.Item
                        name="tag"
                        className="w-100"
                        rules={[{ validator: (_, value) => (!value || value.length <= 20 ? Promise.resolve() : Promise.reject(new Error('최대 20글자를 초과할 수 없습니다.'))) }]}
                      >
                        <Input value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown} placeholder="태그를 입력해 주세요." maxLength={20} />
                      </Form.Item>
                    </span>

                    <span className="tagList">
                      {tags.map((tag, index) => (
                        <div className="tag flexRowCenter" key={index}>
                          <p>#{tag}</p>
                          <img src={closeIcon} onClick={() => handleDelete(tag)} />
                        </div>
                      ))}
                    </span>
                  </div>
                  <p className="blueSpan">
                    * 태그는 최대 10개까지 입력할 수 있으며, 등록한 태그는 마켓플레이스에서 검색 시 사용됩니다. (원하는 단어를 입력한 뒤에 쉼표 혹은 엔터키를 입력하시면 태그가 등록됩니다.)
                  </p>
                </div>
              </div>
            </div>
            <div className="flexRowBetween info-area">
              <div className="title flexRowStart">
                <img src={resultIcon} />
                <h1 className="basicEssential">AI 결과물 등록</h1>
              </div>

              <div className="flexColCenter content">
                <div className="flexColStart box resultBox">
                  {selectedCategory === 'CATE001' && <p className="blueSpan">* 반드시 이미지를 5장 등록해야 합니다.</p>}
                  <ResultComponent cateType={selectedCategory} />
                </div>
              </div>
            </div>
            <div className="flexRowBetween secession">
              <div className="title flexRowCenter">
                <span className="redSpan">* 판매 승인 후에는 일부 항목은 수정이 불가능하니, 반드시 꼼꼼하게 확인하여 등록하시길 바랍니다.</span>
              </div>
            </div>
            {/* 저장 타입 전달용 ('01' 임시저장 / '10' 판매요청)  */}
            <Form.Item name="prpt_stat" hidden>
              <Input />
            </Form.Item>
            <div className="flexRowCenter btnBox" id="nothing">
              <button id="logoutBtn" type="button" onClick={handleInit}>
                취소
              </button>
              <button
                id="darkGrey"
                type="button"
                onClick={() => {
                  // 수정모드일때는 현재 프롬프트 상태값 그대로, 등록모드일때는 임시저장 상태로 설정
                  form.setFieldsValue({ prpt_stat: state?.prpt_id ? currentPrptStat : '01' });
                  setSaveType('01');
                  form.submit();
                }}
              >
                {selectedCategory === 'CATE003' && !currentPrptStat ? '임시저장' : '저장하기'}
              </button>
              {/* 판매요청/판매중/판매중지 상태가 아니고, 판매거절이면서 음악인 경우가 아닐때만 버튼 노출 */}
              {!(['10', '30', '99'].includes(currentPrptStat) || (currentPrptStat === '98' && selectedCategory === 'CATE003')) && (
                <button
                  id="purpleBtn"
                  type="button"
                  onClick={() => {
                    // 음악은 판매중, 이미지/텍스트는 판매요청 상태로 설정
                    form.setFieldsValue({ prpt_stat: selectedCategory === 'CATE003' ? '30' : '10' });
                    setSaveType('10');
                    form.submit();
                  }}
                >
                  {selectedCategory === 'CATE003' ? '등록하기' : '판매요청'}
                </button>
              )}
            </div>
          </Form>
        </div>
      </article>

      {/* 이용약관 모달창 */}
      <SalesTerms visible={salesTermsVisible} onClose={onSalesTermsClose} />

      {/* AI모델 인증 모달창 */}
      <AiModelCertModal visible={isAiModalVisible} onClose={onAiIsModalClose} aiModelCd={selectedAiModel} />

      {/*판매자 등록 모달창*/}
      <SellerRegist visible={isSellerModalVisible} onClose={onSellerModalClose} />
    </>
  );
};

export default SalesPrompt;
