import React, {useCallback, useEffect, useRef, useState} from 'react';
import {message, Image, Form, Input, Upload, Select, Modal, Button} from 'antd';

import {CloseOutlined, PlusOutlined} from '@ant-design/icons';

import { IMAGES } from '@utils/image';
import TextArea from "antd/es/input/TextArea";
import {shallowEqual, useSelector} from "react-redux";
import * as api from "@api/index";
import {handleImageError, makeSelectBoxList} from "@utils/lib";
import useWidth from "@hooks/useWidth";

const GenreField = React.memo(({ genreList, selectedGenre, handleGenreSelect, handleGenreRemove }) => {
    return (
        <>
        <div className="flexColStart gerneSel">
            <label style={{marginBottom: '8px'}}>주요장르</label>
            <div className="flexRowStart">
                <Form.Item name="artist_genre">
                    <Select placeholder="장르 선택" style={{width: '183px', marginRight:'10px'}} options={genreList} onChange={handleGenreSelect} />
                </Form.Item>
                <ul className="tabBox">
                    {selectedGenre.map((genre) => (
                        <li className="genreTab">{genre.label}
                            <span>{genre.genre}
                                <CloseOutlined onClick={() => handleGenreRemove(genre.value)} />
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        </>
    )
});

const artistDivList = [
    { label: '고등학교', value: 'high' },
    { label: '대학교', value: 'university' },
    { label: '일반', value: 'general' },
]

const tpList = [
    { label: '솔로', value: 'solo' },
    { label: '그룹', value: 'group' },
]
const ArtistEdit = ({ isOpen, onClose }) => {
    const auth = useSelector((s) => s.auth, shallowEqual);
    const width = useWidth();

    const [isLoading, setIsLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [rewardText, setRewardText] = useState('');

    const currentYear = new Date().getFullYear(); // 현재 연도

    // 내림차순으로 연도 배열
    const yearOptions = [];
    for (let year = currentYear; year >= 2005; year--) {
        yearOptions.push(
            <Select.Option key={year} value={year}>
                {year}
            </Select.Option>
        );
    }

    const genreRef = useRef(null);
    const [selectedGenre, setSelectedGenre] = useState([]);

    const handleGenreSelect = (value) => {
        const selectedOption = genreCodes.find((genre) => genre.value === value);
        if (selectedOption && !selectedGenre.some((genre) => genre.value === value)) {
            setSelectedGenre([...selectedGenre, selectedOption]);
        }
    };

    const handleGenreRemove = (value) => {
        setSelectedGenre(selectedGenre.filter((genre) => genre.value !== value));
    };

    const [locationCodes, setLocationCodes] = useState([]);
    const [genreCodes, setGenreCodes] = useState([]);
    const [belongCodes, setBelongCodes] = useState([]);

    const fetchCommonCodes = useCallback(async () => {
        try {
            const locCodeList = await api.fetchCommonDetailCode({ grp_cd: 'LOC01' });
            setLocationCodes(makeSelectBoxList(locCodeList?.data, 'cd_desc', 'cd'));

            const genreCodeList = await api.fetchCommonDetailCode({ grp_cd: 'GENRE' });
            setGenreCodes(makeSelectBoxList(genreCodeList?.data, 'cd_desc', 'cd'));

            /*const belongCodeList = await api.fetchCommonDetailCode({ grp_cd: 'BELONG' });
            setBelongCodes(makeSelectBoxList(belongCodeList?.data, 'cd_desc', 'cd'));*/
        } catch (e) {
            message.warning('상세 코드 조회 중 오류가 발생했습니다.');
        }
    },[]);

    const [showBanner, setShowBanner] = useState(false);
    const [profileImageFile, setProfileImageFile] = useState(null);
    const [introImageFile, setIntroImageFile] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [introImageUrl, setIntroImageUrl] = useState(null);

    const { Dragger } = Upload;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const imageProfileError = useRef(false);
    const imageBannerError = useRef(false);
    const [hoverProfileState, setHoverProfileState] = useState(false);
    const [hoverChanelState, setHoverChanelState] = useState(false);
    const defaultProfileChk = useRef(false);
    const defaultIntroChk = useRef(false);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    useEffect(() => {
        imageProfileError.current = false;
        imageBannerError.current = false;
    }, [profileImageUrl, introImageUrl]);

    const handleImageUpload = (file, type) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (type === 'profile') {
                imageProfileError.current = false;
                setProfileImageFile(file);
                setProfileImageUrl(reader.result);
                defaultProfileChk.current = false;
            } else if (type === 'intro') {
                imageBannerError.current = false;
                setIntroImageFile(file);
                setIntroImageUrl(reader.result);
                defaultIntroChk.current = false;
            }
        };
        reader.readAsDataURL(file);
    };

    const renderUpload = (name) => {
        const isProfile = name === 'profile_image';

        const handleHover = (e, type, state) => {
            type === 'profile' ? setHoverProfileState(state) : setHoverChanelState(state);
            const icons = e.currentTarget.querySelectorAll('.iconStyle');
            icons.forEach((icon) => (icon.style.opacity = state ? '1' : '0'));
        };

        const beforeUpload = (file) => {
            handleImageUpload(file, isProfile ? 'profile' : 'intro');
            return false;
        };

        const handleImagePreview = (e, type) => {
            e.stopPropagation();
            const url = type === 'p' ? profileImageUrl : introImageUrl;
            if (url) {
                setPreviewImage(url);
                setPreviewOpen(true);
            }
        };

        const handleImageRemove = (e) => {
            e.stopPropagation();
            if (isProfile) {
                setProfileImageUrl(null);
                setProfileImageFile(null);
                defaultProfileChk.current = false;
            } else {
                setIntroImageUrl(null);
                setIntroImageFile(null);
                defaultIntroChk.current = false;
            }
        };

        const renderImageUpload = (imageUrl, errorRef, hoverState) => (
            <Dragger accept=".jpg,.jpeg,.png" maxCount={1} listType="picture-card" showUploadList={false} beforeUpload={beforeUpload}>
                {imageUrl === null || errorRef.current || (imageUrl !== null && errorRef.current) ? (
                    <>
                        <div className="flexColCenter plus"><PlusOutlined /></div>
                        {width > 700 && (<p>이미지를 등록하거나<br />드래그 & 드롭해 주세요.<br />{isProfile ? "* 권장 300 x 300px" :"* 권장 사이즈 1900 x 320px" }</p>)}
                    </>
                ) : (
                    <div
                        className="flexColCenter imageContainer"
                        onMouseEnter={(e) => handleHover(e, isProfile ? 'profile' : 'banner', true)}
                        onMouseLeave={(e) => handleHover(e, isProfile ? 'profile' : 'banner', false)}
                    >
                        <img className="iconStyle iconExpand" src={IMAGES.FILE_EXPAN_ICON} onClick={(e) => handleImagePreview(e, isProfile ? 'p' : '')} />
                        <img className="iconStyle iconChange" src={IMAGES.FILE_CHANGE_ICON} />
                        <img className="iconStyle iconTrash" src={IMAGES.FILE_TRASH_ICON} onClick={handleImageRemove} />
                        <img src={imageUrl} style={{ filter: hoverState ? 'brightness(0.7)' : 'brightness(1)' }} />
                    </div>
                )}
            </Dragger>
        );

        return (
            <div className="profileBox">
                {previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
                {isEditMode ? (
                    <>
                        <div className={isProfile ? "plusBox flexColCenter" : "flexColCenter plusBannerBox"}>
                            <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile}>
                                {isProfile
                                    ? renderImageUpload(profileImageUrl, imageProfileError, hoverProfileState, 'profile')
                                    : renderImageUpload(introImageUrl, imageBannerError, hoverChanelState, 'banner')}
                            </Form.Item>
                        </div>
                    </>
                ) : (
                    <Image
                        src={isProfile ? profileImageUrl : introImageUrl}
                        className={isProfile ? "profilePreview" : "introPreview"}
                        fallback={isProfile ? IMAGES.DEFAULT_PROFILE : IMAGES.DEFAULT_BANNER}
                        onError={(e) => handleImageError(e, isProfile ? imageProfileError : imageBannerError, isProfile ? IMAGES.DEFAULT_PROFILE : IMAGES.DEFAULT_BANNER)}
                    />
                )}
            </div>
        );
    };

    const [groupMembers, setGroupMembers] = useState([]);
    const uploadImageExtension = process.env.REACT_APP_UPLOAD_IMAGE;
    const [hoverMembersState, setHoverMembersState] = useState(false);

    const handleGroupMemberFileChange = (info, index) => {
        const { file, fileList } = info;

        if (index !== undefined) {
            const updatedMembers = [...groupMembers];
            updatedMembers[index] = {
                ...updatedMembers[index],
                file: file.originFileObj || file,
                id: file.uid,
                hasImage: true,
            };
            setGroupMembers(updatedMembers);
        } else {
            const newFiles = fileList
                .filter((f) => f.status !== 'removed')
                .slice(0, 6 - groupMembers.length)
                .map(f => ({
                    id: f.uid,
                    file: f.originFileObj || f,
                    name: '',
                    hasImage: true,
                }));

            setGroupMembers(prevMembers => {
                const combined = [...prevMembers, ...newFiles];
                return combined.slice(0, 6);
            });
        }
    };

    const handleGroupMemberRemove = (e, memberToRemove) => {
        e.stopPropagation();

        setGroupMembers(prevMembers =>
            prevMembers.filter(member => member.id !== memberToRemove.id)
        );
    };

    const handleGroupProfileRemove = (e, memberToRemove) => {
        e.stopPropagation();

        setGroupMembers(prevMembers =>
            prevMembers.map(member => {
                if (member.id === memberToRemove.id) {
                    return {
                        ...member,
                        file: null,
                        hasImage: false
                    };
                }
                return member;
            })
        );
    };

    const handleGroupMemberPreview = (e, imageUrl) => {
        e.stopPropagation();
        setPreviewImage(imageUrl);
        setPreviewOpen(true);
    };

    const handleNameChange = (e, index) => {
        const newName = e.target.value;
        const updatedMembers = [...groupMembers];
        updatedMembers[index] = {
            ...updatedMembers[index],
            name: newName
        };
        setGroupMembers(updatedMembers);
    };

    const beforeUpload = (file, type, errorMessage) => {
        const validate = file.type.indexOf(type);

        if (validate < 0) {
            message.error(errorMessage);
            return Upload.LIST_IGNORE;
        }
        return false;
    };

    const [newMemberName, setNewMemberName] = useState('');

    const handleAddMemberByName = () => {
        if (!newMemberName.trim()) {
            message.warning('멤버 이름을 입력해주세요.');
            return;
        }

        if (groupMembers.length >= 6) {
            message.warning('그룹 멤버는 최대 6명까지 추가할 수 있습니다.');
            return;
        }

        const newMember = {
            id: `member-${Date.now()}`,
            name: newMemberName,
            hasImage: false
        };

        setGroupMembers(prevMembers => [...prevMembers, newMember]);
        setNewMemberName('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleAddMemberByName();
        }
    };

    const handleSubmit = async () => {
        try {
            const values = form.getFieldsValue();
            await form.validateFields();

            const params = {
                mem_key: auth?.mem_key,
                artist_plan: 'FREE',
                artist_nm: values.artist_nm,
                artist_div: values.artist_div,
                local: values.local,
                edu_nm: values.edu_nm,
                major: values.major,
                yos: values.yos,
                artist_genre: selectedGenre.map(item => item.value).join('|'),
                artist_tp: values.artist_tp,
                artist_inst: values.artist_inst,
                artist_hist: values.artist_hist,
            };
            console.log('=== 제출 데이터 ===');
            console.log('최종 제출 데이터:', params);
            console.log('데이터:', values);

            // api.updateMemberLeave(params);
            message.success('아티스트 등록이 완료되었습니다.');
            // onClose();
            form.resetFields();
            setGroupMembers([]);
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    useEffect(() => {
        fetchCommonCodes();
        setIsEditMode(true);
        setRewardText('');
        setGroupMembers([]);
    },[isOpen]);

    useEffect(() => {
        if (isOpen) {
            const originalStyle = window.getComputedStyle(document.body).overflow;

            document.body.style.overflow = 'hidden';
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.body.style.paddingRight = `${scrollbarWidth}px`;

            return () => {
                document.body.style.overflow = originalStyle;
                document.body.style.paddingRight = '0px';
            };
        }
    }, [isOpen]);

    return (
      <Modal className="artistModal" title="아티스트 등록" open={isOpen} onCancel={onClose} width={800}
        footer={[
          <Button id="purpleBtn" key="submit" onClick={handleSubmit} /*disabled={!agreed || !isPwCert}*/>
            다음
          </Button>,
          <Button id="logoutBtn" key="" onClick={onClose}>
            취소
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" requiredMark={true} initialValues={{}}>
            <div className="form1">
                <div className="mainOpt">
                    <div className="mainOpt-left">
                        {/* 아티스트명, 지역, 소속 */}
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <Form.Item name="artist_nm" label="아티스트명" style={{ flex: 1 }} rules={[{ required: true, message: '아티스트명을 입력해주세요.' }]}>
                                <Input placeholder="아티스트명" />
                            </Form.Item>
                            <Form.Item name="local" label="지역" rules={[{ required: true, message: '지역을 선택해주세요.' }]} style={{ flex: 1 }}>
                                <Select placeholder="지역" options={locationCodes} />
                            </Form.Item>
                            <Form.Item name="artist_div" label="소속" rules={[{ required: true, message: '소속을 선택해주세요.' }]} style={{ flex: 1 }}>
                                <Select placeholder="소속" options={artistDivList} />
                            </Form.Item>
                        </div>
                        {/* 학교정보 */}
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <Form.Item name="edu_nm" label="학교명" style={{ flex: 1 }}>
                                <Input placeholder="학교명" />
                            </Form.Item>
                            <Form.Item name="major" label="학과" style={{ flex: 1 }}>
                                <Input placeholder="학과명" />
                            </Form.Item>
                            <Form.Item name="yos" label="입학년도" rules={[{ required: true, message: '입학년도를 선택해주세요.' }]} style={{ flex: 1 }}>
                                <Select placeholder="입학년도를 선택하세요">
                                    {yearOptions}
                                </Select>
                            </Form.Item>
                        </div>
                        {/* 주요장르 */}
                        <GenreField genreList={genreCodes} selectedGenre={selectedGenre} handleGenreSelect={handleGenreSelect} handleGenreRemove={handleGenreRemove} />
                    </div>
                    <div className="mainOpt-right" id="artistProfile">
                        <label>아티스트 프로필 이미지</label>
                        <div className="profileUpload">{renderUpload('profile_image')}</div>
                        <Button id="darkGrey" onClick={()=>setShowBanner(!showBanner)}>아티스트채널 배너등록</Button>
                    </div>
                </div>

                <Modal style={{display: !showBanner ? 'none': ''}}>
                    <div id="artistBanner">
                        <div className="flexColStart info">{renderUpload('intro_image')}</div>
                    </div>
                </Modal>

                <div className="artistGroup">
                    <div className="flexRowBetween" style={{marginBottom: '8px'}}>
                        <label>아티스트 구성</label>
                        <span id="groupExplain" style={{color: '#2687e9'}}>* 그룹일 경우, 리더 1명만 아티스트 등록을 해주세요.</span>
                    </div>
                   <div className="artistGroup-input">
                        <Form.Item name="artist_tp">
                            <Select placeholder="구성" options={tpList} />
                        </Form.Item>
                        <div>
                            <Input
                                placeholder="멤버 이름 입력"
                                value={newMemberName}
                                onChange={(e) => setNewMemberName(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                            <Button
                                type="primary"
                                onClick={handleAddMemberByName}
                                style={{ marginLeft: '5px' }}
                            >
                                추가
                            </Button>
                        </div>
                   </div>
                </div>
                
                <div className="member" style={{ marginBottom: '20px' }}>
                    <label>멤버</label>
                    <ul className="artistGruop-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {groupMembers.map((member, index) => (
                            <li key={member.id} style={{ width: 'calc(30% - 12px)', marginBottom: '10px' }}>
                                <div className="member-container" style={{ display: 'flex', flexDirection: 'column' }}>
                                    {member.hasImage ? (
                                        <Upload.Dragger
                                            accept={uploadImageExtension}
                                            fileList={[]}
                                            className="flexColCenter plusBox"
                                            onChange={(info) => handleGroupMemberFileChange(info, index)}
                                            beforeUpload={(file) => beforeUpload(file, 'image', '카테고리에 맞는 파일을 선택해주세요.')}
                                            showUploadList={false}
                                        >
                                            <div className="flexColCenter plusBox">
                                                <div className="flexColCenter imageContainer" style={{ position: 'relative' }}
                                                     onMouseEnter={(e) => {
                                                         setHoverMembersState(true);
                                                         const icons = e.currentTarget.parentElement.querySelectorAll('.iconStyle');
                                                         icons.forEach((icon) => (icon.style.opacity = '1'));
                                                     }}
                                                     onMouseLeave={(e) => {
                                                         setHoverMembersState(false);
                                                         const icons = e.currentTarget.parentElement.querySelectorAll('.iconStyle');
                                                         icons.forEach((icon) => (icon.style.opacity = '0'));
                                                     }}
                                                >
                                                    <img
                                                        src={IMAGES.FILE_EXPAN_ICON}
                                                        alt="expand icon"
                                                        className="iconStyle iconExpand"
                                                        onClick={(e) => handleGroupMemberPreview(e, URL.createObjectURL(member.file))}
                                                        style={{ opacity: 0, position: 'absolute', top: '10px', right: '70px', zIndex: 10, cursor: 'pointer' }}
                                                    />
                                                    <img
                                                        src={IMAGES.FILE_CHANGE_ICON}
                                                        alt="change icon"
                                                        className="iconStyle iconChange"
                                                        style={{ opacity: 0, position: 'absolute', top: '10px', right: '40px', zIndex: 10, cursor: 'pointer' }}
                                                    />
                                                    <img
                                                        src={IMAGES.FILE_TRASH_ICON}
                                                        alt="trash icon"
                                                        className="iconStyle iconTrash"
                                                        onClick={(e) => handleGroupProfileRemove(e, member)}
                                                        style={{ opacity: 0, position: 'absolute', top: '10px', right: '10px', zIndex: 10, cursor: 'pointer' }}
                                                    />
                                                    <img
                                                        src={URL.createObjectURL(member.file)}
                                                        alt=""
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover', filter: hoverMembersState ? 'brightness(0.7)' : 'brightness(1)' }}
                                                    />
                                                </div>
                                            </div>
                                        </Upload.Dragger>
                                    ) : (
                                        <Upload.Dragger
                                            accept={uploadImageExtension}
                                            fileList={[]}
                                            className="flexColCenter plusBox"
                                            onChange={(info) => handleGroupMemberFileChange(info, index)}
                                            beforeUpload={(file) => beforeUpload(file, 'image', '카테고리에 맞는 파일을 선택해주세요.')}
                                            showUploadList={false}
                                        >
                                            <div className="flexColCenter">
                                                <div className="plus flexColCenter">
                                                    <PlusOutlined />
                                                </div>
                                                {width > 700 && (
                                                    <p style={{ fontSize: '11px' }}>
                                                        그룹 멤버 이미지를 등록하거나
                                                        <br />
                                                        드래그&드롭해 주세요.
                                                        <br />
                                                        (* 권장 500 x 500px)
                                                    </p>
                                                )}
                                            </div>
                                        </Upload.Dragger>
                                    )}
                                    <div className="" style={{ display: 'flex', marginTop: '5px' }}>
                                        <Input
                                            placeholder="그룹 멤버 이름"
                                            style={{ flex: 1 }}
                                            value={member.name}
                                            onChange={(e) => handleNameChange(e, index)}
                                        />
                                        <Button
                                            icon={<CloseOutlined />}
                                            style={{ marginLeft: '5px', width: '5px' }}
                                            onClick={() => handleGroupMemberRemove({ stopPropagation: () => {} }, member)}
                                        />
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </div>
            <div className="form2">
                <Form.Item name="artist_inst" label="채널 소개글" rules={[{ required: true, message: '소개글을 입력해주세요.' }]}>
                    <TextArea style={{ height:'65px'}} rows={4} placeholder="소개글을 입력해주세요." />
                </Form.Item>
                <Form.Item name="artist_hist" label="아티스트 수상이력" rules={[{ required: true, message: '수상이력을 입력해주세요.' }]}>
                    <TextArea
                    style={{ height:'100px'}}
                    rows={4}
                    value={rewardText}
                    onChange={(e) => setRewardText(e.target.value)}
                    placeholder="수상이력을 입력해주세요."
                    />
                </Form.Item>
            </div>
        </Form>
      </Modal>
    );
};
export default ArtistEdit;
